define("plutof/templates/organization/add", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "8tzDohHi",
    "block": "[[[10,0],[14,0,\"route-marker-organization-add\"],[14,\"data-test\",\"route-organization.add\"],[12],[13],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@icon\",\"@title\",\"@record\",\"@reset\",\"@goBack\",\"@infoContent\"],[[28,[37,1],[\"organization\"],null],[28,[37,2],[\"Agents.newOrganization\"],null],[30,0,[\"model\",\"organization\"]],[30,0,[\"routeActions\",\"triggerReset\"]],[30,0,[\"routeActions\",\"goBack\"]],\"information/content/organization/general\"]],null],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@model\",\"@validationContext\"],[[30,0,[\"model\",\"organization\"]],[30,0,[\"validationContext\"]]]],null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@save\",\"@validationContext\",\"@goBack\"],[[30,0,[\"save\"]],[30,0,[\"validationContext\"]],[30,0,[\"routeActions\",\"goBack\"]]]],null],[1,\"\\n\"]],[],false,[\"navbar/edit\",\"icon\",\"i18n-t\",\"agent/organization/edit\",\"common/save-panel\"]]",
    "moduleName": "plutof/templates/organization/add.hbs",
    "isStrictMode": false
  });
});