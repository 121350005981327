define("plutof/mixins/add-controller", ["exports", "@ember/object/mixin"], function (_exports, _mixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AddControllerMixin = _mixin.default.create({
    actions: {
      cancel: function () {
        this.set('routeHasBeenLoaded', false);
        this.set('template_id', null);
        this.routeActions.goBack();
      }
    }
  });
  var _default = _exports.default = AddControllerMixin;
});