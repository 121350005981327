define("plutof/components/common/save-and-reset", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PromiseButton
      @clicked={{@save}}
      @disabled={{@disabled}}
      @restIcon={{icon "add-new"}}
      @tooltip={{i18n-t "cloning.saveAndAddNewTooltip"}}
      class="btn btn-default save-and-new-button"
  >
      {{i18n-t "cloning.saveAndAddNew"}}
  </PromiseButton>
  
  */
  {
    "id": "6SHjOTpL",
    "block": "[[[8,[39,0],[[24,0,\"btn btn-default save-and-new-button\"]],[[\"@clicked\",\"@disabled\",\"@restIcon\",\"@tooltip\"],[[30,1],[30,2],[28,[37,1],[\"add-new\"],null],[28,[37,2],[\"cloning.saveAndAddNewTooltip\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,2],[\"cloning.saveAndAddNew\"],null]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@save\",\"@disabled\"],false,[\"promise-button\",\"icon\",\"i18n-t\"]]",
    "moduleName": "plutof/components/common/save-and-reset.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});