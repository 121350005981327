define("plutof/templates/study/add", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "zq1vnYXW",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"study.add\"]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@icon\",\"@title\",\"@record\",\"@reset\",\"@goBack\",\"@infoContent\"],[[28,[37,2],[\"project\"],null],[28,[37,3],[\"Projects.newP\"],null],[30,0,[\"model\",\"study\"]],[30,0,[\"routeActions\",\"triggerReset\"]],[30,0,[\"routeActions\",\"goBack\"]],\"information/content/project/general\"]],null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@project\",\"@projectPersons\",\"@addPerson\",\"@removePerson\",\"@projectAreas\",\"@addProjectArea\",\"@removeProjectArea\",\"@useNewLogo\",\"@identifiers\",\"@associatedData\",\"@workgroupData\",\"@accessRights\",\"@validationContext\"],[[30,0,[\"model\",\"study\"]],[30,0,[\"model\",\"projectPersons\"]],[30,0,[\"addPerson\"]],[30,0,[\"removePerson\"]],[30,0,[\"model\",\"projectAreas\"]],[30,0,[\"addProjectArea\"]],[30,0,[\"removeProjectArea\"]],[30,0,[\"useNewLogo\"]],[30,0,[\"model\",\"identifiers\"]],[30,0,[\"model\",\"itemData\"]],[30,0,[\"model\",\"workgroupData\"]],[30,0,[\"model\",\"accessRights\"]],[30,0,[\"validationContext\"]]]],null],[1,\"\\n\\n\"],[8,[39,5],null,[[\"@save\",\"@validationContext\",\"@goBack\"],[[30,0,[\"save\"]],[30,0,[\"validationContext\"]],[30,0,[\"routeActions\",\"goBack\"]]]],null],[1,\"\\n\"]],[],false,[\"test/route-marker\",\"navbar/edit\",\"icon\",\"i18n-t\",\"study/edit\",\"common/save-panel\"]]",
    "moduleName": "plutof/templates/study/add.hbs",
    "isStrictMode": false
  });
});