define("plutof/templates/treatment/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "y4WIgEnk",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"treatment.index\"]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@icon\",\"@title\",\"@addRoute\",\"@goBack\",\"@pagination\",\"@infoTitle\"],[[28,[37,2],[\"treatment\"],null],[28,[37,3],[\"CollectionManagement.treatmentsTitle\"],null],\"treatment.add\",[30,0,[\"routeActions\",\"goBack\"]],[30,0,[\"model\",\"pagination\"]],[28,[37,3],[\"CollectionManagement.treatmentsInfoTitle\"],null]]],null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@records\"],[[30,0,[\"model\",\"pagination\",\"objects\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,1],null,[[\"@label\"],[[28,[37,3],[\"PlutofListViewColumn.treatmentDescription\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,5],null,[[\"@route\",\"@model\"],[\"treatment.view\",[30,2,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,2,[\"description\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,1],null,[[\"@label\"],[[28,[37,3],[\"PlutofListViewColumn.specimenID\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,2,[\"specimens\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,1],null,[[\"@label\"],[[28,[37,3],[\"PlutofListViewColumn.count\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,2,[\"count\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,3,[\"modified\"]],null,null,null],[1,\"\\n    \"],[8,[30,3,[\"access\"]],null,null,null],[1,\"\\n\"]],[1,2,3]]]]],[1,\"\\n\"]],[\"Field\",\"treatment\",\"common\"],false,[\"test/route-marker\",\"navbar/list\",\"icon\",\"i18n-t\",\"tables/list-route\",\"link-to\"]]",
    "moduleName": "plutof/templates/treatment/index.hbs",
    "isStrictMode": false
  });
});