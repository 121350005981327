define("plutof/templates/permit/add", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "ERQwt8t0",
    "block": "[[[8,[39,0],null,[[\"@icon\",\"@title\",\"@reset\",\"@goBack\"],[[28,[37,1],[\"permit\"],null],[28,[37,2],[\"permit.add\"],null],[30,0,[\"routeActions\",\"triggerReset\"]],[30,0,[\"routeActions\",\"goBack\"]]]],null],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@model\",\"@validationContext\"],[[30,0,[\"model\",\"permit\"]],[30,0,[\"validationContext\"]]]],null],[1,\"\\n\\n\"],[8,[39,4],null,null,[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"action-buttons\"],[12],[1,\"\\n        \"],[8,[39,5],null,[[\"@save\",\"@disabled\"],[[30,0,[\"save\"]],[30,0,[\"validationContext\",\"isInvalid\"]]]],null],[1,\"\\n\\n        \"],[8,[39,6],null,[[\"@cancel\"],[[28,[37,7],[[30,0],\"cancel\"],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"navbar/edit\",\"icon\",\"i18n-t\",\"collection-lab/permit/edit\",\"sticky-bottom-wrapper\",\"common/save-button\",\"common/cancel-button\",\"action\"]]",
    "moduleName": "plutof/templates/permit/add.hbs",
    "isStrictMode": false
  });
});