define("plutof/templates/layer/edit", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "SeOq6ah8",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"layer.edit\"]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@icon\",\"@title\",\"@record\",\"@goBack\",\"@infoContent\"],[[28,[37,2],[\"area\"],null],[28,[37,3],[\"Layer.editLayer\"],null],[30,0,[\"model\",\"samplingarea\"]],[30,0,[\"routeActions\",\"goBack\"]],\"information/content/gis-lab/layers\"]],null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@layer\",\"@areas\",\"@save\",\"@validationContext\"],[[30,0,[\"model\",\"layer\"]],[30,0,[\"model\",\"areas\"]],[30,0,[\"save\"]],[30,0,[\"validationContext\"]]]],null],[1,\"\\n\\n\"],[8,[39,5],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,6],null,[[\"@validationContext\"],[[30,0,[\"validationContext\"]]]],null],[1,\"\\n\\n    \"],[10,0],[14,0,\"action-buttons\"],[12],[1,\"\\n        \"],[8,[39,7],null,[[\"@save\",\"@disabled\"],[[30,0,[\"save\"]],[30,0,[\"validationContext\",\"isInvalid\"]]]],null],[1,\"\\n\\n        \"],[8,[39,8],null,[[\"@cancel\"],[[30,0,[\"routeActions\",\"goBack\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"test/route-marker\",\"navbar/edit\",\"icon\",\"i18n-t\",\"layer/edit\",\"sticky-bottom-wrapper\",\"invalid-panels\",\"common/save-button\",\"common/cancel-button\"]]",
    "moduleName": "plutof/templates/layer/edit.hbs",
    "isStrictMode": false
  });
});