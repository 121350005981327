define("plutof/templates/annotations/bulk/view", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "8j53Tx6j",
    "block": "[[[8,[39,0],null,[[\"@icon\",\"@title\",\"@annotation\",\"@permissions\",\"@goBack\"],[[28,[37,1],[\"annotation\"],null],[28,[37,2],[\"annotation.routes.bulk.view.title\"],null],[30,0,[\"model\",\"annotation\"]],[30,0,[\"model\",\"permissions\"]],[30,0,[\"routeActions\",\"goBack\"]]]],null],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@annotation\"],[[30,0,[\"model\",\"annotation\"]]]],null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@contentType\",\"@records\"],[[30,0,[\"model\",\"annotation\",\"content_type\",\"full_name\"]],[30,0,[\"model\",\"records\"]]]],null],[1,\"\\n\"]],[],false,[\"annotation/navbar/view\",\"icon\",\"i18n-t\",\"annotation/bulk/summary\",\"annotation/bulk/records\"]]",
    "moduleName": "plutof/templates/annotations/bulk/view.hbs",
    "isStrictMode": false
  });
});