define("plutof/templates/transaction/add", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "JKoSIHAB",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"transaction.add\"]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@icon\",\"@title\",\"@record\",\"@reset\",\"@goBack\",\"@infoContent\"],[[28,[37,2],[\"transaction\"],null],[28,[37,3],[\"transactions.newTitle\"],null],[30,0,[\"model\",\"transaction\"]],[30,0,[\"routeActions\",\"triggerReset\"]],[30,0,[\"routeActions\",\"goBack\"]],\"information/content/collection/transaction\"]],null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@model\",\"@validationContext\",\"@forceExpand\"],[[30,0,[\"model\",\"transaction\"]],[30,0,[\"validationContext\"]],[30,0,[\"forceExpand\"]]]],null],[1,\"\\n\\n\"],[8,[39,5],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,6],null,[[\"@validationContext\"],[[30,0,[\"validationContext\"]]]],null],[1,\"\\n\\n\"],[41,[28,[37,8],[[30,0,[\"model\",\"transaction\",\"hasRequiredRoles\"]]],null],[[[1,\"        \"],[10,2],[12],[1,\"\\n            \"],[10,\"strong\"],[14,0,\"invalid-panel\"],[12],[1,\"\\n                \"],[1,[28,[35,3],[\"transactions.invalidRoles\"],null]],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[10,0],[14,0,\"action-buttons\"],[12],[1,\"\\n        \"],[8,[39,9],null,[[\"@save\",\"@disabled\"],[[30,0,[\"save\"]],[30,0,[\"validationContext\",\"isInvalid\"]]]],null],[1,\"\\n\\n        \"],[8,[39,10],null,[[\"@cancel\"],[[28,[37,11],[[30,0],\"cancel\"],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"test/route-marker\",\"navbar/edit\",\"icon\",\"i18n-t\",\"transaction/edit\",\"sticky-bottom-wrapper\",\"invalid-panels\",\"if\",\"not\",\"common/save-button\",\"common/cancel-button\",\"action\"]]",
    "moduleName": "plutof/templates/transaction/add.hbs",
    "isStrictMode": false
  });
});