define("plutof/components/conservation/red-list/view/permissions", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple @title={{i18n-t "General.access"}}>
      <Access::ViewRightsBase as |vr|>
          <vr.panel>
              <vr.view.public />
              <vr.edit.selected @groups={{array @workgroup}} />
          </vr.panel>
  
          <vr.panel>
              <vr.history
                  @createdBy={{@redlist.created_by.representation}}
                  @createdAt={{@redlist.created_at}}
                  @updatedBy={{@redlist.modified_by.representation}}
                  @updatedAt={{@redlist.modified_at}} />
          </vr.panel>
      </Access::ViewRightsBase>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "viC5M2W9",
    "block": "[[[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"General.access\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[30,1,[\"panel\"]],null,null,[[\"default\"],[[[[1,\"\\n            \"],[8,[30,1,[\"view\",\"public\"]],null,null,null],[1,\"\\n            \"],[8,[30,1,[\"edit\",\"selected\"]],null,[[\"@groups\"],[[28,[37,3],[[30,2]],null]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,1,[\"panel\"]],null,null,[[\"default\"],[[[[1,\"\\n            \"],[8,[30,1,[\"history\"]],null,[[\"@createdBy\",\"@createdAt\",\"@updatedBy\",\"@updatedAt\"],[[30,3,[\"created_by\",\"representation\"]],[30,3,[\"created_at\"]],[30,3,[\"modified_by\",\"representation\"]],[30,3,[\"modified_at\"]]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[1]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"vr\",\"@workgroup\",\"@redlist\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"access/view-rights-base\",\"array\"]]",
    "moduleName": "plutof/components/conservation/red-list/view/permissions.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});