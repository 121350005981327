define("plutof/templates/layer/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "WvasDUx0",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"layer.index\"]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@icon\",\"@title\",\"@pagination\",\"@goBack\",\"@infoTitle\",\"@infoContent\"],[[28,[37,2],[\"area\"],null],[28,[37,3],[\"Layer.layers\"],null],[30,0,[\"pagination\"]],[30,0,[\"routeActions\",\"goBack\"]],[28,[37,3],[\"Layer.layers\"],null],\"information/content/gis-lab/layers\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"primaryControls\"]],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"canAdd\"]],[[[1,\"            \"],[8,[30,1,[\"button\"]],[[24,0,\"navbar-list__add\"]],[[\"@clicked\",\"@icon\"],[[30,0,[\"add\"]],[28,[37,2],[\"add-new\"],null]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[28,[35,3],[\"General.New\"],null]],[1,\"\\n            \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"    \"]],[]]]]],[1,\"\\n\"]],[1,2]]]]],[1,\"\\n\\n\"],[8,[39,5],null,[[\"@records\"],[[30,0,[\"pagination\",\"objects\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,3],null,[[\"@label\"],[[28,[37,3],[\"PlutofListViewColumn.name\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,6],null,[[\"@route\",\"@model\"],[\"layer.view\",[30,4,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,4,[\"name\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,5,[\"description\"]],null,null,null],[1,\"\\n    \"],[8,[30,5,[\"modified\"]],null,null,null],[1,\"\\n\"]],[3,4,5]]]]],[1,\"\\n\"]],[\"navbar\",\"slots\",\"Field\",\"layer\",\"common\"],false,[\"test/route-marker\",\"navbar/list\",\"icon\",\"i18n-t\",\"if\",\"tables/list-route\",\"link-to\"]]",
    "moduleName": "plutof/templates/layer/index.hbs",
    "isStrictMode": false
  });
});