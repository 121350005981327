define("plutof/templates/forms/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "4EO25F8u",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"forms.index\"]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@icon\",\"@title\",\"@addRoute\",\"@goBack\",\"@pagination\",\"@infoTitle\",\"@infoContent\"],[[28,[37,2],[\"form\"],null],[28,[37,3],[\"Forms.title\"],null],\"forms.add\",[30,0,[\"routeActions\",\"goBack\"]],[30,0,[\"model\",\"pagination\"]],[28,[37,3],[\"Forms.title\"],null],\"information/content/forms/index\"]],null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@model\"],[\"measurement.mainform\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,5],null,[[\"@records\"],[[30,0,[\"model\",\"pagination\",\"objects\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,2],null,[[\"@label\"],[[28,[37,3],[\"PlutofListViewColumn.name\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,6],null,[[\"@route\",\"@model\"],[\"forms.view\",[30,3,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[30,3,[\"name\"]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,2],null,[[\"@label\"],[[28,[37,3],[\"PlutofListViewColumn.type\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,7],[[30,1,[\"type\",\"choices\"]],[30,3,[\"type\"]]],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,4,[\"modified\"]],null,null,null],[1,\"\\n    \"]],[2,3,4]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"translations\",\"Field\",\"form\",\"common\"],false,[\"test/route-marker\",\"navbar/list\",\"icon\",\"i18n-t\",\"i18n/model-strings\",\"tables/list-route\",\"link-to\",\"get\"]]",
    "moduleName": "plutof/templates/forms/index.hbs",
    "isStrictMode": false
  });
});