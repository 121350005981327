define("plutof/templates/taxon-description/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "Wkm++Us4",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"taxon-description.index\"]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@icon\",\"@title\",\"@addRoute\",\"@pagination\",\"@infoTitle\",\"@goBack\"],[[28,[37,2],[\"taxon-description\"],null],[28,[37,3],[\"Taxonomy.descriptionsTitle\"],null],\"taxon-description.add\",[30,0,[\"model\",\"pagination\"]],[28,[37,3],[\"Taxonomy.descriptionsInfoTitle\"],null],[30,0,[\"routeActions\",\"goBack\"]]]],null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@records\"],[[30,0,[\"model\",\"pagination\",\"objects\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,1],null,[[\"@label\"],[[28,[37,3],[\"PlutofListViewColumn.taxon\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,5],null,[[\"@route\",\"@model\"],[\"taxon-description.view\",[30,2,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,2,[\"taxon_name\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,3,[\"modified\"]],null,null,null],[1,\"\\n    \"],[8,[30,3,[\"access\"]],null,null,null],[1,\"\\n\"]],[1,2,3]]]]],[1,\"\\n\"]],[\"Field\",\"taxonDescription\",\"common\"],false,[\"test/route-marker\",\"navbar/list\",\"icon\",\"i18n-t\",\"tables/list-route\",\"link-to\"]]",
    "moduleName": "plutof/templates/taxon-description/index.hbs",
    "isStrictMode": false
  });
});