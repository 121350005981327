define("plutof/templates/experiment/pcr/add", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "dptU099i",
    "block": "[[[10,0],[14,0,\"route-marker-pcr-add\"],[12],[13],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@icon\",\"@title\",\"@record\",\"@reset\",\"@goBack\",\"@infoContent\"],[[28,[37,1],[\"experiment\"],null],[28,[37,2],[\"experiment.pcr.routeTitles.add\"],null],[30,0,[\"model\",\"experiment\"]],[30,0,[\"routeActions\",\"triggerReset\"]],[30,0,[\"routeActions\",\"goBack\"]],\"information/content/dna-lab/general\"]],null],[1,\"\\n\\n\"],[1,[28,[35,3],null,[[\"experiment\",\"materials\",\"linkedItemsData\",\"validationContext\",\"cancel\",\"save\"],[[30,0,[\"model\",\"experiment\"]],[30,0,[\"model\",\"materials\"]],[30,0,[\"model\",\"linkedItemsData\"]],[30,0,[\"validationContext\"]],[28,[37,4],[[30,0],\"cancel\"],null],[28,[37,4],[[30,0],\"saveExperiment\"],null]]]]],[1,\"\\n\"]],[],false,[\"navbar/edit\",\"icon\",\"i18n-t\",\"experiment/pcr/edit-form\",\"action\"]]",
    "moduleName": "plutof/templates/experiment/pcr/add.hbs",
    "isStrictMode": false
  });
});