define("plutof/models/taxonoccurrence/specimen/specimen", ["exports", "@ember/object", "@ember-data/model", "plutof/mixins/model_mixins", "plutof/models/taxonoccurrence/taxonoccurrence-model"], function (_exports, _object, _model, _model_mixins, _taxonoccurrenceModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let Specimen = (_dec = (0, _model.belongsTo)('measurement/mainform'), _dec2 = (0, _model.belongsTo)('agent/collection'), _dec3 = (0, _model.belongsTo)('agent/repository'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.belongsTo)('taxonoccurrence/specimen/exsiccata'), _dec11 = (0, _model.attr)('string'), _dec12 = (0, _model.belongsTo)('substrate/substrate', {
    own: true
  }), _dec13 = (0, _model.belongsTo)('taxonoccurrence/materialsample/materialsample'), _dec14 = (0, _model.belongsTo)('taxonoccurrence/livingculture/strain'), _dec15 = (0, _model.attr)('enum'), _dec16 = (0, _model.attr)('string'), _dec17 = (0, _model.hasMany)('agent/person'), _dec18 = (0, _model.attr)('json'), _dec19 = (0, _model.attr)('boolean'), _dec20 = (0, _model.attr)('boolean'), _dec21 = (0, _model.attr)('number'), _dec22 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec23 = (0, _model.attr)('number'), _dec24 = (0, _object.computed)('specimen_idprim', 'specimen_idprim_sub'), _class = class Specimen extends _taxonoccurrenceModel.default.extend(_model_mixins.DeterminationsMixin) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "modelType", 'specimen/specimen');
      _initializerDefineProperty(this, "mainform", _descriptor, this);
      _initializerDefineProperty(this, "deposited_in", _descriptor2, this);
      _initializerDefineProperty(this, "sub_repository", _descriptor3, this);
      _initializerDefineProperty(this, "location_in_collection", _descriptor4, this);
      _initializerDefineProperty(this, "specimen_idprim", _descriptor5, this);
      _initializerDefineProperty(this, "specimen_idprim_sub", _descriptor6, this);
      _initializerDefineProperty(this, "remarks", _descriptor7, this);
      _initializerDefineProperty(this, "attrib_isolation_date", _descriptor8, this);
      _initializerDefineProperty(this, "attrib_emergence_date", _descriptor9, this);
      _initializerDefineProperty(this, "exsiccata", _descriptor10, this);
      _initializerDefineProperty(this, "exsiccata_no", _descriptor11, this);
      _initializerDefineProperty(this, "substrate", _descriptor12, this);
      _initializerDefineProperty(this, "related_materialsample", _descriptor13, this);
      _initializerDefineProperty(this, "related_living_specimen", _descriptor14, this);
      _initializerDefineProperty(this, "ls_relationship_type", _descriptor15, this);
      // Readonly
      _initializerDefineProperty(this, "taxon_name", _descriptor16, this);
      _initializerDefineProperty(this, "gathering_agents", _descriptor17, this);
      _initializerDefineProperty(this, "related_objects", _descriptor18, this);
      _initializerDefineProperty(this, "on_clipboard", _descriptor19, this);
      _initializerDefineProperty(this, "in_gbif", _descriptor20, this);
      _initializerDefineProperty(this, "active_transaction_id", _descriptor21, this);
      _initializerDefineProperty(this, "transfer_restriction", _descriptor22, this);
      _initializerDefineProperty(this, "download_count", _descriptor23, this);
    }
    get representation() {
      return this.specimen_idprim_sub ? `${this.specimen_idprim}.${this.specimen_idprim_sub}` : this.specimen_idprim;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "mainform", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "deposited_in", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "sub_repository", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "location_in_collection", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "specimen_idprim", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "specimen_idprim_sub", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "remarks", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "attrib_isolation_date", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "attrib_emergence_date", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "exsiccata", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "exsiccata_no", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "substrate", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "related_materialsample", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "related_living_specimen", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "ls_relationship_type", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "taxon_name", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "gathering_agents", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "related_objects", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "on_clipboard", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "in_gbif", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "active_transaction_id", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "transfer_restriction", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "download_count", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "representation", [_dec24], Object.getOwnPropertyDescriptor(_class.prototype, "representation"), _class.prototype), _class);
  var _default = _exports.default = Specimen;
});