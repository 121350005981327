define("plutof/templates/sequence/parse-file", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "q1M14+sf",
    "block": "[[[8,[39,0],null,[[\"@icon\",\"@title\",\"@goBack\"],[[28,[37,1],[\"sequence\"],null],[30,0,[\"model\",\"file\",\"representation\"]],[30,0,[\"routeActions\",\"goBack\"]]]],null],[1,\"\\n\\n\"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"\\n    \"],[11,0],[24,0,\"unite-table__alignment-container\"],[4,[38,3],[[30,0,[\"sequences\"]],[30,0,[\"buildAlignmentConfig\"]]],null],[12],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"navbar/view\",\"icon\",\"plutof-panel/headerless\",\"unite/alignment-renderer\"]]",
    "moduleName": "plutof/templates/sequence/parse-file.hbs",
    "isStrictMode": false
  });
});