define("plutof/templates/sequence/annotation/add", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "tLA5pB0/",
    "block": "[[[8,[39,0],null,[[\"@kind\",\"@icon\",\"@title\",\"@goBack\",\"@infoContent\"],[\"annotation\",[28,[37,1],[\"sequence\"],null],[28,[37,2],[\"Sequences.annotation.add\"],null],[30,0,[\"routeActions\",\"goBack\"]],\"information/sequence/edit\"]],null],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@data\",\"@validationContext\",\"@showSourceSelector\"],[[30,0,[\"model\",\"sequenceData\"]],[30,0,[\"validationContext\"]],true]],null],[1,\"\\n\\n\"],[8,[39,4],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,5],null,[[\"@validationContext\"],[[30,0,[\"validationContext\"]]]],null],[1,\"\\n\\n    \"],[10,0],[14,0,\"action-buttons\"],[12],[1,\"\\n        \"],[8,[39,6],null,[[\"@annotate\",\"@disabled\"],[[30,0,[\"annotate\"]],[30,0,[\"validationContext\",\"isInvalid\"]]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"enableElixirAnnotations\"]],[[[1,\"            \"],[8,[39,8],null,[[\"@record\",\"@extract\",\"@send\",\"@disabled\"],[[30,0,[\"model\",\"sequenceData\",\"sequence\"]],[30,0,[\"extractElixirCurations\"]],[30,0,[\"sendElixirAnnotation\"]],[30,0,[\"validationContext\",\"isInvalid\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n        \"],[8,[39,9],null,[[\"@cancel\"],[[30,0,[\"routeActions\",\"goBack\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"navbar/edit\",\"icon\",\"i18n-t\",\"sequence/edit\",\"sticky-bottom-wrapper\",\"invalid-panels\",\"annotation/annotate-button\",\"if\",\"annotation/elixir/annotate-button\",\"common/cancel-button\"]]",
    "moduleName": "plutof/templates/sequence/annotation/add.hbs",
    "isStrictMode": false
  });
});