define("plutof/translations/est/unite-sh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    SHsearch: 'otsida liigihüpoteese SH koodi, taksoninime ja sekventsi koodide alusel.',
    // In this section you can ...
    SHrefs: 'kuvada SH referentssekventse.',
    // In this section you can ...
    SHtaxonomyBrowser: 'kuvada SH-sid eluslooduse klassifikatsioonis.',
    // In this section you can ...
    statisticsInfo: 'kuvada SH-de statistika.',
    // In this section you can ...
    downloads: 'alla laadida UNITE SH-de referentsandmeid.',
    // In this section you can ...
    SHS: 'SH otsing',
    SHR: 'SH RefS',
    SHTB: 'SH taksonoomia lehitseja',
    USH: 'UNITE Liigihüpoteesid',
    referenceSequence: 'Reference sequence',
    centroidSequence: 'Centroid sequence',
    distance: 'Distance (%)',
    insertedBy: 'Kellepoolt sisestatud',
    instertedAt: 'Kuna sisestatud',
    shCode: 'SH kood',
    accessionNumbers: 'Accession numbers',
    version: 'Versioon',
    refseqChosenBy: 'Valinud',
    refseqChosenAt: 'Kuupäev',
    refseqDesignatedAt: 'Designated at',
    unassignRefseq: 'Unassign',
    lineage: 'Placement in PlutoF classification',
    sequenceSource: 'DNA Source',
    selectedSequences: 'Selected sequences',
    sequenceID: 'Sequence ID',
    location: 'Maa',
    refseq: 'RefSeq',
    actions: 'Tegevused',
    selectDistance: 'Vali läviväärtus',
    includeSingletons: 'Kaasa',
    onlySingletons: 'Only singletons',
    onlyNonsingletons: 'Only non-singletons',
    allSHs: 'All SH-s',
    conflicts: 'Conflicts',
    conflictSH: 'SH',
    conflictFirstSeq: 'First sequence',
    conflictSecondSeq: 'Second sequence',
    searchBySH: 'Otsi UNITE SH koodi järgi',
    searchTitle: 'Otsi liigihüpoteese',
    downloadFASTA: 'FASTA',
    statistics: {
      total: 'Total',
      clusters: 'Total no. of SH-s',
      singletons: 'No. of singleton SH-s',
      referencedClusters: 'No. of referenced SH-s',
      referencedSingletons: 'No. of referenced singleton SH-s'
    },
    shView: {
      title: 'Species Hypotheses (SH)',
      shortName: 'SH short name',
      communication: 'Communication of the SH',
      thTaxonomy: 'Placement in Taxon Hypotheses',
      previousDOIVersion: 'Eelmine versioon'
    },
    collapse: {
      title: 'Collapsible views',
      level: 'Tase {{level}}',
      levelDescriptions: {
        0: 'Sequences within the same 1.0% SH for the same continent collapsed',
        1: 'Sequences within the same 1.0% SH for the same country collapsed',
        2: 'Sequences within the same 0.5% SH for the same continent collapsed',
        3: 'Sequences within the same 0.5% SH for the same country collapsed',
        4: 'Identical sequences for the same continent collapsed',
        5: 'Identical sequences for the same country collapsed',
        6: 'All sequences belonging to the SH are shown'
      }
    },
    taxonSearchPlaceholder: 'Otsi taksoni järgi',
    versionCreatedAt: 'Arvutatud',
    colorSequences: 'Värvid',
    treshold: 'Distants',
    nrOfSeq: 'No. of seqs',
    refseqChosenByII: 'RefSeq chosen by',
    additionalViews: 'Lisa vaated',
    alignmentView: 'Aligneeringu vaade',
    clusterView: 'Ühendklastri vaade',
    determinations: 'Liigihüpoteeside määrangud',
    refseqList: 'Referentssekventsid',
    description: 'Eluslooduse klassifikatsioooniga ühendatud seente DNA-põhised liigihüpoteesid.',
    DoiSHref: {
      refSeq: 'Reference sequence',
      chosenBy: 'Valitud',
      date: 'Kuupäev',
      minimumDistance: 'Vähim kaugus lähimast SH-st',
      nrOfSeqInSH: 'Sekventside arv SH-s',
      placementInClassif: 'Asend eluslooduse süsteemis',
      statistics: 'Statistika',
      distrOffDist: 'Distribution of distances',
      listOfIds: 'Määrangud',
      listOfInterTaxa: 'List of interacting taxa',
      seqID: 'Sequence ID',
      seqRepresentative: 'Esindussekvents',
      UniteTaxName: 'UNITE taxon name',
      INSDtaxName: 'INSD taxon name',
      country: 'Country',
      DNAsource: 'DNA source',
      interactTaxa: 'Interacting taxa',
      chimeric: 'Chimeric',
      lowq: 'Low quality',
      locked: 'Lukustatud',
      uniteCoreSeq: 'UNITE core sequence',
      automaticallyChosen: 'automatically chosen SH representative sequence',
      EX: 'EX = sequence to be excluded from the next version of global key',
      reprSeq: 'ReprSeq',
      seqIDShort: 'Seq ID',
      accessionNr: 'Koodid',
      linkToIndexFungorium: 'Index Fungorumis',
      see: 'Vaata',
      locationsWithoutCord: '* Ilma täpsete koordinaatideta asukohad on kaardil märgitud riigi tsentroidina',
      distributionMap: 'Levikukaart',
      ecology: 'Ökoloogia',
      shGraphicalView: 'SH graphical view',
      refSeqShort: 'RefSeq',
      speciesHypothesis: 'Species Hypothesis pages version 7',
      ECMLineage: 'Ektomükoriisa takson',
      galleryTitle: 'Galerii',
      showOldTable: 'Kuva vana aligneeringu tabel'
    },
    clusters: {
      title: 'Ühendklastrid',
      accession: 'Accession Number',
      source: 'Allikas',
      interaction: 'Interakteeruv takson',
      area: 'Ala',
      alignment: 'Aligneering',
      nrOfSeq: 'Sekventside arv',
      uniteTaxon: 'Unite taksoni nimi',
      insdTaxon: 'INSD taksoni nimi'
    }
  };
});