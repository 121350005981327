define("plutof/templates/annotations/bulk/add", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "sXrwWTHX",
    "block": "[[[8,[39,0],null,[[\"@kind\",\"@icon\",\"@title\",\"@goBack\"],[\"annotation\",[28,[37,1],[\"annotation\"],null],[28,[37,2],[\"annotation.routes.bulk.add.title\"],null],[30,0,[\"routeActions\",\"goBack\"]]]],null],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@contentType\",\"@records\"],[[30,0,[\"model\",\"annotation\",\"contentType\"]],[30,0,[\"model\",\"annotation\",\"records\"]]]],null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@fields\"],[[30,0,[\"model\",\"annotation\",\"fields\"]]]],null],[1,\"\\n\\n\"],[8,[39,5],null,null,[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"action-buttons\"],[12],[1,\"\\n        \"],[8,[39,6],null,[[\"@annotation\",\"@annotate\",\"@publicationChoice\"],[[30,0,[\"model\",\"annotation\"]],[30,0,[\"save\"]],true]],null],[1,\"\\n\\n        \"],[8,[39,7],null,[[\"@cancel\"],[[28,[37,8],[[30,0],\"cancel\"],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"navbar/edit\",\"icon\",\"i18n-t\",\"annotation/bulk/records\",\"annotation/bulk/sections/sequence\",\"sticky-bottom-wrapper\",\"annotation/bulk/annotate-button\",\"common/cancel-button\",\"action\"]]",
    "moduleName": "plutof/templates/annotations/bulk/add.hbs",
    "isStrictMode": false
  });
});