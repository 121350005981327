define("plutof/templates/dmp/edit", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "1FoYQ+ah",
    "block": "[[[8,[39,0],null,[[\"@icon\",\"@title\",\"@record\",\"@goBack\",\"@infoTitle\",\"@infoContent\"],[[28,[37,1],[\"dmp\"],null],[28,[37,2],[\"dmp.routeTitles.edit\"],null],[30,0,[\"model\",\"dmp\"]],[30,0,[\"routeActions\",\"goBack\"]],[28,[37,2],[\"dmp.general.dmp\"],null],\"information/content/dmp/general\"]],null],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@data\",\"@cancel\",\"@save\",\"@accessRights\",\"@validationContext\"],[[30,0,[\"model\",\"dmp\"]],[28,[37,4],[[30,0],\"cancel\"],null],[28,[37,4],[[30,0],\"saveDMP\"],null],[30,0,[\"model\",\"accessRights\"]],[30,0,[\"validationContext\"]]]],null],[1,\"\\n\"]],[],false,[\"navbar/edit\",\"icon\",\"i18n-t\",\"dmp/edit\",\"action\"]]",
    "moduleName": "plutof/templates/dmp/edit.hbs",
    "isStrictMode": false
  });
});