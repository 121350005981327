define("plutof/routes/materialsample/add", ["exports", "@ember/service", "@ember/utils", "rsvp", "plutof/components/interaction/edit-multiple", "plutof/components/layer/occurrence-areas", "plutof/components/linked-items/edit", "plutof/components/materialsample/materialsample-table", "plutof/misc/profile_settings", "plutof/utils/cloning", "plutof/utils/model", "plutof/utils/routes", "plutof/utils/reflection"], function (_exports, _service, _utils, _rsvp, _editMultiple, _occurrenceAreas, _edit, _materialsampleTable, _profile_settings, _cloning, _model, _routes, _reflection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  async function cloneMaterialSample(store, template, cloneArea, cloneEvent) {
    const msample = await (0, _cloning.clone_record)(store, template);

    // TODO: DRY, almost the same as in observation.add
    const samplingData = await (0, _model.getOccurrenceSamplingData)(store, template).then(samplingData => {
      return (0, _cloning.clone_sampling_data)(store, samplingData.formData, cloneArea, cloneEvent);
    });
    msample.set('name', null);
    msample.set('samplingevent', samplingData.get('event'));
    return _rsvp.default.hash({
      occurrenceTableData: _materialsampleTable.MaterialSampleData.initialize(store, [msample]),
      samplingData: samplingData,
      layers: _occurrenceAreas.LinkedLayersModel.clone({
        ajax: (0, _reflection.getService)(store, 'ajax'),
        templateOccurrence: template
      }),
      mainform: template.get('mainform'),
      itemData: (0, _cloning.clone_linked_items)(store, template, msample, true, true)
    });
  }
  let MaterialsampleAddRoute = (_class = class MaterialsampleAddRoute extends _routes.AddRoute {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "fileUpload", _descriptor, this);
      _initializerDefineProperty(this, "ajax", _descriptor2, this);
      _defineProperty(this, "defaultReturnRoute", 'materialsample.index');
      _defineProperty(this, "title", 'MaterialSamples.newSample');
    }
    async createMaterialsample(params) {
      const store = this.store;
      if (!(0, _utils.isNone)(params) && !(0, _utils.isNone)(params.template_id)) {
        const template = await store.findRecord('taxonoccurrence/materialsample/materialsample', params.template_id);
        return cloneMaterialSample(store, template, params.clone_area === 'true', params.clone_event === 'true');
      }
      let mainform;
      if ((0, _utils.isNone)(params.mainform_id)) {
        const settings = await (0, _profile_settings.get_personal_settings)(store);
        mainform = await settings.get('materialsample_form');
      } else {
        mainform = await store.findRecord('measurement/mainform', params.mainform_id);
      }
      return _rsvp.default.hash({
        occurrenceTableData: _materialsampleTable.MaterialSampleData.initialize(store, this.fileUpload, null, {
          collectionID: params.collection_id
        }),
        samplingData: (0, _model.getSamplingDataForParams)(store, params).then(sdata => sdata.formData),
        layers: _occurrenceAreas.LinkedLayersModel.create(this.ajax),
        mainform,
        itemData: (0, _edit.createMultipleLinkedItemsData)(store, {
          useEventFiles: true,
          disabledTabs: []
        })
      });
    }
    model(params) {
      if (this.get('controller.routeHasBeenLoaded')) {
        return undefined;
      }
      return this.createMaterialsample(params).then(data => {
        return _rsvp.default.hash({
          formData: data.samplingData,
          materialSamplesData: data.occurrenceTableData,
          interactions: new _editMultiple.UnboundInteractionsData({
            store: this.store
          }),
          itemData: data.itemData,
          layers: data.layers,
          selectedMeasurementsForm: data.mainform,
          additionalIdentifiers: []
        });
      });
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      if (!(0, _utils.isNone)(model)) {
        controller.setProperties({
          interacting_content_object: null,
          interacting_taxon_type: null,
          interaction_type: null,
          interaction_remarks: null,
          mainform: null,
          closePanels: false,
          routeHasBeenLoaded: true,
          sharedFileWarningShown: false
        });
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "fileUpload", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  var _default = _exports.default = MaterialsampleAddRoute;
});