define("plutof/components/specimen/view/relationships", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#resolve-promise @specimen.related_living_specimen as |relatedLS|}}
      <PlutofPanel::Simple
          @title={{i18n-t "Specimen.relationships.relationships"}}
          @collapse={{not relatedLS}}
      >
          <GenericViews::Record @record={{@specimen}} as |view|>
              <Layout::RegularGrid @columns={{4}} @cellGap={{true}}>
                  {{view.field "related_living_specimen"}}
                  {{view.field "ls_relationship_type"}}
              </Layout::RegularGrid>
          </GenericViews::Record>
      </PlutofPanel::Simple>
  {{/resolve-promise}}
  
  */
  {
    "id": "uRSO2r19",
    "block": "[[[6,[39,0],[[30,1,[\"related_living_specimen\"]]],null,[[\"default\"],[[[[1,\"    \"],[8,[39,1],null,[[\"@title\",\"@collapse\"],[[28,[37,2],[\"Specimen.relationships.relationships\"],null],[28,[37,3],[[30,2]],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,4],null,[[\"@record\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,5],null,[[\"@columns\",\"@cellGap\"],[4,true]],[[\"default\"],[[[[1,\"\\n                \"],[1,[28,[30,3,[\"field\"]],[\"related_living_specimen\"],null]],[1,\"\\n                \"],[1,[28,[30,3,[\"field\"]],[\"ls_relationship_type\"],null]],[1,\"\\n            \"]],[]]]]],[1,\"\\n        \"]],[3]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[2]]]]]],[\"@specimen\",\"relatedLS\",\"view\"],false,[\"resolve-promise\",\"plutof-panel/simple\",\"i18n-t\",\"not\",\"generic-views/record\",\"layout/regular-grid\"]]",
    "moduleName": "plutof/components/specimen/view/relationships.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});