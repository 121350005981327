define("plutof/components/validation/-group/part", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield this.context}}
  
  */
  {
    "id": "WJokXaWI",
    "block": "[[[18,1,[[30,0,[\"context\"]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "plutof/components/validation/-group/part.hbs",
    "isStrictMode": false
  });
  // Should this have willDestroy, removing the created context?
  let ValidationGroupPart = _exports.default = (_dec = (0, _object.computed)('args.parent', 'args.label'), _class = class ValidationGroupPart extends _component2.default {
    get context() {
      const id = this.args.label.toString().replace(/\./g, '');
      if (!this.args.parent.get(id)) {
        this.args.parent.addChild(id, this.args.label);
      }
      return this.args.parent.get(id);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "context", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "context"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ValidationGroupPart);
});