define("plutof/components/specimen/relationships", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "plutof/utils/model"], function (_exports, _component, _templateFactory, _component2, _object, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "Specimen.relationships.relationships"}}
      @collapse={{this.collapseInitially}}
  >
      <GenericForms::Record @record={{@specimen}} as |form|>
          <Layout::RegularGrid @columns={{2}}>
              {{form.bind "related_living_specimen"}}
              {{form.bind "ls_relationship_type"}}
          </Layout::RegularGrid>
      </GenericForms::Record>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "FZG+Sv4P",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@collapse\"],[[28,[37,1],[\"Specimen.relationships.relationships\"],null],[30,0,[\"collapseInitially\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@record\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@columns\"],[2]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[30,2,[\"bind\"]],[\"related_living_specimen\"],null]],[1,\"\\n            \"],[1,[28,[30,2,[\"bind\"]],[\"ls_relationship_type\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[2]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@specimen\",\"form\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"generic-forms/record\",\"layout/regular-grid\"]]",
    "moduleName": "plutof/components/specimen/relationships.hbs",
    "isStrictMode": false
  });
  let SpecimenRelationships = _exports.default = (_dec = (0, _object.computed)('args.specimen'), _class = class SpecimenRelationships extends _component2.default {
    // Note that this doesn't recalculate on related_living_specimen. This is because we
    // need it only for the initial render, then it's up to user to expand/collapse.
    get collapseInitially() {
      return !(0, _model.hasRelation)(this.args.specimen, 'related_living_specimen');
    }
  }, _applyDecoratedDescriptor(_class.prototype, "collapseInitially", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "collapseInitially"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SpecimenRelationships);
});