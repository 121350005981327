define("plutof/templates/components/experiment/pcr/edit-form", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "NNnyjmBg",
    "block": "[[[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"General.generalData\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,2],null,[[\"experiment\",\"materialIsInvalid\",\"validationContext\"],[[30,1],[30,0,[\"materialIsInvalid\"]],[30,2,[\"metadata\"]]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[1,[28,[35,3],null,[[\"experiment\",\"products\",\"addLabware\",\"validationContext\"],[[30,1],[30,3],[28,[37,4],[[30,0],[30,0,[\"addLabware\"]]],null],[30,2,[\"material\"]]]]]],[1,\"\\n\\n\"],[8,[39,5],null,[[\"@experiment\",\"@materials\",\"@materialType\"],[[30,1],[30,3],\"pcr\"]],null],[1,\"\\n\\n\"],[1,[28,[35,6],null,[[\"data\",\"validationContext\"],[[30,4],[30,2,[\"associatedData\"]]]]]],[1,\"\\n\\n\"],[8,[39,7],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,8],null,[[\"@validationContext\"],[[30,2]]],null],[1,\"\\n\\n    \"],[10,0],[14,0,\"action-buttons\"],[12],[1,\"\\n        \"],[8,[39,9],null,[[\"@save\",\"@disabled\"],[[28,[37,4],[[30,0],[30,5]],null],[30,2,[\"isInvalid\"]]]],null],[1,\"\\n\\n        \"],[8,[39,10],null,[[\"@cancel\"],[[28,[37,4],[[30,0],[30,6]],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@experiment\",\"@validationContext\",\"@materials\",\"@linkedItemsData\",\"@save\",\"@cancel\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"experiment/pcr/experiment-metadata\",\"experiment/pcr/experiment-material\",\"action\",\"experiment/common/labware-visualization\",\"linked-items/edit\",\"sticky-bottom-wrapper\",\"invalid-panels\",\"common/save-button\",\"common/cancel-button\"]]",
    "moduleName": "plutof/templates/components/experiment/pcr/edit-form.hbs",
    "isStrictMode": false
  });
});