define("plutof/templates/annotations/moderated", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "2j9nxrM/",
    "block": "[[[8,[39,0],null,[[\"@icon\",\"@title\",\"@goBack\",\"@pagination\"],[[28,[37,1],[\"annotation\"],null],[28,[37,2],[\"annotation.routes.moderated.title\"],null],[30,0,[\"routeActions\",\"goBack\"]],[30,0,[\"model\",\"annotations\"]]]],null],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@records\"],[[30,0,[\"model\",\"annotations\",\"objects\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,4],null,[[\"@field\",\"@annotation\"],[[30,1],[30,2]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,3,[\"icon\"]],null,null,null],[1,\"\\n        \"],[8,[30,3,[\"link\"]],null,null,null],[1,\"\\n        \"],[8,[30,3,[\"author\"]],null,null,null],[1,\"\\n        \"],[8,[30,3,[\"comment\"]],null,null,null],[1,\"\\n        \"],[8,[30,3,[\"annotatedAt\"]],null,null,null],[1,\"\\n    \"]],[3]]]]],[1,\"\\n\"]],[1,2]]]]],[1,\"\\n\"]],[\"Field\",\"annotation\",\"fields\"],false,[\"navbar/list\",\"icon\",\"i18n-t\",\"tables/list-route\",\"annotation/fields\"]]",
    "moduleName": "plutof/templates/annotations/moderated.hbs",
    "isStrictMode": false
  });
});