define("plutof/misc/resetmixin", ["exports", "@ember/object/mixin", "@ember/object", "@ember/utils", "plutof/misc/abstract", "plutof/mixins/route", "plutof/utils/objects"], function (_exports, _mixin, _object, _utils, _abstract, _route, _objects) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let ResettableRouteActions = (_class = class ResettableRouteActions extends _route.ControllerRouteActions {
    triggerReset() {
      this._route._triggerReset();
    }
  }, _applyDecoratedDescriptor(_class.prototype, "triggerReset", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "triggerReset"), _class.prototype), _class); // TODO: Move to app/mixins
  var _default = _exports.default = _mixin.default.create({
    paramsClearedOnReset: [],
    extractAllParams: function (transition) {
      const routeParams = transition.to.params;
      const queryParams = transition.to.queryParams;
      return (0, _abstract.mergeObjects)(routeParams, queryParams);
    },
    beforeModel: function (transition) {
      this._super(...arguments);
      let controller = this.controller;

      // Note: for this to work with save/cancel, they should set routeHasBeenLoaded to true
      if (!(0, _utils.isNone)(controller)) {
        const currentParams = this.extractAllParams(transition);
        const savedParams = this.savedParams;
        if ((0, _utils.isNone)(savedParams) || Object.keys(currentParams).length !== 0 && !(0, _objects.objects_are_equal)(currentParams, savedParams)) {
          controller.set('routeHasBeenLoaded', false);
        }
      }
    },
    afterModel: function (model, transition) {
      this._super(model, transition);
      if (!(0, _utils.isNone)(model)) {
        this.set('savedRouteParams', transition.to.params);
        this.set('savedParams', this.extractAllParams(transition));
      }
    },
    buildControllerRouteActions: function () {
      return new ResettableRouteActions(this);
    },
    setupController: function (controller, model) {
      this._super(...arguments);
      if ((0, _utils.isNone)(model)) {
        controller.setProperties(this.savedParams);
      }
      controller.set('routeHasBeenLoaded', true);
    },
    clearQueryParams: function () {
      let controller = this.controller;
      if ((0, _utils.isNone)(controller) || controller.get('keepQueryParams')) {
        return;
      }
      const queryParams = controller.get('queryParams') || [];
      queryParams.forEach(qparam => {
        controller.set(qparam, null);
      });
    },
    _triggerReset: function () {
      this.clearQueryParams();
      this.controller.set('routeHasBeenLoaded', false);
      this.model(this.savedRouteParams).then(resolvedModel => {
        this.set('savedParams', this.savedRouteParams);
        this.set('controller.model', resolvedModel);
        this.set('controller.routeHasBeenLoaded', true);
      });
    },
    actions: {
      triggerReset: function () {
        this._triggerReset();
      },
      willTransition: function (transition) {
        this._super(...arguments);
        const controller = this.controller;
        const reloadModel = !(0, _utils.isNone)(controller) && !controller.get('routeHasBeenLoaded');
        // Don't clear qp-s if we are transitioning to the same route and the model will be reloaded
        if (!reloadModel || transition.targetName !== this.routeName) {
          this.clearQueryParams();
        }
      }
    }
  });
});