define("plutof/templates/livingspecimen/annotation/view", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "iLRiSGeq",
    "block": "[[[8,[39,0],null,[[\"@icon\",\"@title\",\"@annotation\",\"@permissions\",\"@parentRoute\",\"@goBack\"],[[28,[37,1],[\"livingspecimen\"],null],[28,[37,2],[\"LivingCultures.annotation.view\"],null],[30,0,[\"model\",\"annotation\"]],[30,0,[\"model\",\"permissions\"]],\"livingspecimen\",[30,0,[\"routeActions\",\"goBack\"]]]],null],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@annotation\",\"@permissions\"],[[30,0,[\"model\",\"annotation\"]],[30,0,[\"model\",\"permissions\"]]]],null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@livingspecimen\",\"@parent\",\"@additionalIdentifiers\",\"@accessRights\",\"@sequences\"],[[30,0,[\"model\",\"strain\"]],[30,0,[\"model\",\"parent\"]],[30,0,[\"model\",\"additionalIdentifiers\"]],[30,0,[\"model\",\"accessRights\"]],[30,0,[\"model\",\"sequences\"]]]],null],[1,\"\\n\"]],[],false,[\"annotation/navbar/view\",\"icon\",\"i18n-t\",\"annotation/summary\",\"livingspecimen/view\"]]",
    "moduleName": "plutof/templates/livingspecimen/annotation/view.hbs",
    "isStrictMode": false
  });
});