define("plutof/templates/components/navbar/-navbar-base/go-back", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "Fb6BVAnq",
    "block": "[[[8,[39,0],[[24,0,\"go-back-link\"]],[[\"@clicked\"],[[28,[37,1],[[30,1],null,[28,[37,2],null,[[\"enableClurrentPath\"],[[30,2]]]]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[10,1],[15,0,[28,[37,3],[\"back\"],null]],[12],[13],[1,\"\\n    \"],[1,[28,[35,4],[\"General.Back\"],null]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@goBack\",\"@sameRoute\"],false,[\"navbar/-navbar-base/button\",\"fn\",\"hash\",\"icon\",\"i18n-t\"]]",
    "moduleName": "plutof/templates/components/navbar/-navbar-base/go-back.hbs",
    "isStrictMode": false
  });
});