define("plutof/components/trait/view/permissions", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "ember-concurrency", "plutof/config/environment"], function (_exports, _component, _templateFactory, _component2, _service, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple @title={{i18n-t "General.access"}}>
      <Access::ViewRightsBase as |vr|>
          <vr.panel>
              <vr.view.public />
  
              {{#if this.fetchManagingGroup.lastSuccessful.value}}
                  <vr.edit.selected @groups={{array this.fetchManagingGroup.lastSuccessful.value}} />
              {{else}}
                  <vr.edit.private />
              {{/if}}
          </vr.panel>
  
          <vr.panel>
              <vr.history
                  @createdBy={{@trait.created_by.representation}}
                  @createdAt={{@trait.created_at}}
                  @updatedBy={{@trait.updated_by.representation}}
                  @updatedAt={{@trait.updated_at}} />
          </vr.panel>
      </Access::ViewRightsBase>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "xoDG6XyV",
    "block": "[[[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"General.access\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[30,1,[\"panel\"]],null,null,[[\"default\"],[[[[1,\"\\n            \"],[8,[30,1,[\"view\",\"public\"]],null,null,null],[1,\"\\n\\n\"],[41,[30,0,[\"fetchManagingGroup\",\"lastSuccessful\",\"value\"]],[[[1,\"                \"],[8,[30,1,[\"edit\",\"selected\"]],null,[[\"@groups\"],[[28,[37,4],[[30,0,[\"fetchManagingGroup\",\"lastSuccessful\",\"value\"]]],null]]],null],[1,\"\\n\"]],[]],[[[1,\"                \"],[8,[30,1,[\"edit\",\"private\"]],null,null,null],[1,\"\\n\"]],[]]],[1,\"        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,1,[\"panel\"]],null,null,[[\"default\"],[[[[1,\"\\n            \"],[8,[30,1,[\"history\"]],null,[[\"@createdBy\",\"@createdAt\",\"@updatedBy\",\"@updatedAt\"],[[30,2,[\"created_by\",\"representation\"]],[30,2,[\"created_at\"]],[30,2,[\"updated_by\",\"representation\"]],[30,2,[\"updated_at\"]]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[1]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"vr\",\"@trait\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"access/view-rights-base\",\"if\",\"array\"]]",
    "moduleName": "plutof/components/trait/view/permissions.hbs",
    "isStrictMode": false
  });
  let TraitPermissions = (_class = class TraitPermissions extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "fetchManagingGroup", _descriptor2, this);
      this.fetchManagingGroup.perform();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fetchManagingGroup", [_emberConcurrency.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        if (!_environment.default.TRAIT_GROUP_ID) {
          return null;
        }
        const group = yield this.store.findRecord('users/workgroup', _environment.default.TRAIT_GROUP_ID);
        return group;
      };
    }
  }), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TraitPermissions);
});