define("plutof/templates/analysis/edit", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "okt9MIiD",
    "block": "[[[10,0],[14,0,\"route-marker-analysis-edit\"],[12],[13],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@icon\",\"@title\",\"@record\",\"@goBack\",\"@infoContent\"],[[28,[37,1],[\"analysis\"],null],[28,[37,2],[\"Analysis.editAnalysis\"],null],[30,0,[\"model\",\"run\"]],[30,0,[\"routeActions\",\"goBack\"]],\"information/content/analysis/general\"]],null],[1,\"\\n\\n\"],[1,[28,[35,3],null,[[\"run\",\"type\",\"name\",\"sourceData\",\"validationContext\",\"save\",\"saveAndRun\",\"cancel\"],[[33,4],[33,5],[33,4,[\"name\"]],[33,6],[33,7],[28,[37,8],[[30,0],\"save\"],null],[28,[37,8],[[30,0],\"saveAndRun\"],null],[28,[37,8],[[30,0],\"cancel\"],null]]]]],[1,\"\\n\"]],[],false,[\"navbar/edit\",\"icon\",\"i18n-t\",\"analysis/edit-form\",\"run\",\"type\",\"sourceData\",\"validationContext\",\"action\"]]",
    "moduleName": "plutof/templates/analysis/edit.hbs",
    "isStrictMode": false
  });
});