define("plutof/components/save-panel", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="save-panel">
      <Common::SaveButton
          @save={{@save}}
          @disabled={{@validationContext.isInvalid}}
          class="save-panel__btn" />
  
      <Common::CancelButton @cancel={{@cancel}} />
  
      <Common::SaveAndReset
          @save={{@saveAndReset}}
          @disabled={{@validationContext.isInvalid}}
          class="btn btn-default save-and-new-button save-panel__btn" />
  
      <PromiseButton
          @clicked={{fn (action @clone) true true}}
          @disabled={{@validationContext.isInvalid}}
          @restIcon={{icon "clone"}}
          @tooltip={{i18n-t "cloning.saveAndCloneTooltip"}}
          class="btn btn-default clone-button save-panel__btn"
      >
          {{i18n-t "cloning.saveAndClone"}}
      </PromiseButton>
  
      <PromiseButton
          @clicked={{fn (action @clone) false true}}
          @disabled={{@validationContext.isInvalid}}
          @restIcon={{icon "use-as-template"}}
          @tooltip={{i18n-t "cloning.saveUseAreaTooltip"}}
          class="btn btn-default link-to-button save-panel__btn"
      >
          {{i18n-t "cloning.saveUseArea"}}
      </PromiseButton>
  
      <PromiseButton
          @clicked={{fn (action @clone) false false}}
          @disabled={{@validationContext.isInvalid}}
          @restIcon={{icon "use-as-template"}}
          @tooltip={{i18n-t "cloning.saveUseEventTooltip"}}
          class="btn btn-default save-panel__btn"
      >
          {{i18n-t "cloning.saveUseEvent"}}
      </PromiseButton>
  </div>
  
  */
  {
    "id": "vctc7Mv4",
    "block": "[[[10,0],[14,0,\"save-panel\"],[12],[1,\"\\n    \"],[8,[39,0],[[24,0,\"save-panel__btn\"]],[[\"@save\",\"@disabled\"],[[30,1],[30,2,[\"isInvalid\"]]]],null],[1,\"\\n\\n    \"],[8,[39,1],null,[[\"@cancel\"],[[30,3]]],null],[1,\"\\n\\n    \"],[8,[39,2],[[24,0,\"btn btn-default save-and-new-button save-panel__btn\"]],[[\"@save\",\"@disabled\"],[[30,4],[30,2,[\"isInvalid\"]]]],null],[1,\"\\n\\n    \"],[8,[39,3],[[24,0,\"btn btn-default clone-button save-panel__btn\"]],[[\"@clicked\",\"@disabled\",\"@restIcon\",\"@tooltip\"],[[28,[37,4],[[28,[37,5],[[30,0],[30,5]],null],true,true],null],[30,2,[\"isInvalid\"]],[28,[37,6],[\"clone\"],null],[28,[37,7],[\"cloning.saveAndCloneTooltip\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,7],[\"cloning.saveAndClone\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[39,3],[[24,0,\"btn btn-default link-to-button save-panel__btn\"]],[[\"@clicked\",\"@disabled\",\"@restIcon\",\"@tooltip\"],[[28,[37,4],[[28,[37,5],[[30,0],[30,5]],null],false,true],null],[30,2,[\"isInvalid\"]],[28,[37,6],[\"use-as-template\"],null],[28,[37,7],[\"cloning.saveUseAreaTooltip\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,7],[\"cloning.saveUseArea\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[39,3],[[24,0,\"btn btn-default save-panel__btn\"]],[[\"@clicked\",\"@disabled\",\"@restIcon\",\"@tooltip\"],[[28,[37,4],[[28,[37,5],[[30,0],[30,5]],null],false,false],null],[30,2,[\"isInvalid\"]],[28,[37,6],[\"use-as-template\"],null],[28,[37,7],[\"cloning.saveUseEventTooltip\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,7],[\"cloning.saveUseEvent\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@save\",\"@validationContext\",\"@cancel\",\"@saveAndReset\",\"@clone\"],false,[\"common/save-button\",\"common/cancel-button\",\"common/save-and-reset\",\"promise-button\",\"fn\",\"action\",\"icon\",\"i18n-t\"]]",
    "moduleName": "plutof/components/save-panel.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});