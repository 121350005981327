define("plutof/components/search/filters/typification", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "@ember/utils", "plutof/utils/store"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _service, _utils, _store) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @filter}}
      <TooltipWrapper as |t|>
          <div class="filter-input" data-input={{@filter.config.label}}>
              <div class="label-control">
                  {{#if @existsFilter}}
                      <Search::Filters::Checkbox
                          @filter={{@existsFilter}}
                          @changed={{this.updateFilters}}
                      >
                          {{@filter.config.label}}
                      </Search::Filters::Checkbox>
                  {{else}}
                      <div class="plutof-label">
                          {{@filter.config.label}}
                      </div>
                  {{/if}}
              </div>
  
              {{#resolve-promise this.typifications wait=true as |typifications|}}
                  <PlutofSelect::Object
                      @content={{typifications}}
                      @oneWay={{true}}
                      @selection={{this.dummy}}
                      @selectionChanged={{this.add}}
                      @disabled={{this.disableSelection}} />
  
                  <Common::RecordList
                      @records={{@filter.value}}
                      @remove={{this.remove}} />
              {{/resolve-promise}}
  
              <span id="{{t.tid}}" class="wrapper-tooltip">
                  {{#if @filter.config.help_text}}
                      {{@filter.config.help_text}}
                  {{else}}
                      {{@filter.config.label}}
                  {{/if}}
              </span>
          </div>
      </TooltipWrapper>
  {{/if}}
  
  */
  {
    "id": "e2VJ9l9v",
    "block": "[[[41,[30,1],[[[1,\"    \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"filter-input\"],[15,\"data-input\",[30,1,[\"config\",\"label\"]]],[12],[1,\"\\n            \"],[10,0],[14,0,\"label-control\"],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"                    \"],[8,[39,2],null,[[\"@filter\",\"@changed\"],[[30,3],[30,0,[\"updateFilters\"]]]],[[\"default\"],[[[[1,\"\\n                        \"],[1,[30,1,[\"config\",\"label\"]]],[1,\"\\n                    \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"                    \"],[10,0],[14,0,\"plutof-label\"],[12],[1,\"\\n                        \"],[1,[30,1,[\"config\",\"label\"]]],[1,\"\\n                    \"],[13],[1,\"\\n\"]],[]]],[1,\"            \"],[13],[1,\"\\n\\n\"],[6,[39,3],[[30,0,[\"typifications\"]]],[[\"wait\"],[true]],[[\"default\"],[[[[1,\"                \"],[8,[39,4],null,[[\"@content\",\"@oneWay\",\"@selection\",\"@selectionChanged\",\"@disabled\"],[[30,4],true,[30,0,[\"dummy\"]],[30,0,[\"add\"]],[30,0,[\"disableSelection\"]]]],null],[1,\"\\n\\n                \"],[8,[39,5],null,[[\"@records\",\"@remove\"],[[30,1,[\"value\"]],[30,0,[\"remove\"]]]],null],[1,\"\\n\"]],[4]]]]],[1,\"\\n            \"],[10,1],[15,1,[29,[[30,2,[\"tid\"]]]]],[14,0,\"wrapper-tooltip\"],[12],[1,\"\\n\"],[41,[30,1,[\"config\",\"help_text\"]],[[[1,\"                    \"],[1,[30,1,[\"config\",\"help_text\"]]],[1,\"\\n\"]],[]],[[[1,\"                    \"],[1,[30,1,[\"config\",\"label\"]]],[1,\"\\n\"]],[]]],[1,\"            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"]],[2]]]]],[1,\"\\n\"]],[]],null]],[\"@filter\",\"t\",\"@existsFilter\",\"typifications\"],false,[\"if\",\"tooltip-wrapper\",\"search/filters/checkbox\",\"resolve-promise\",\"plutof-select/object\",\"common/record-list\"]]",
    "moduleName": "plutof/components/search/filters/typification.hbs",
    "isStrictMode": false
  });
  let TypificationSearchFilter = _exports.default = (_dec = (0, _object.computed)('args.existsFilter.value'), _class = class TypificationSearchFilter extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "dummy", _descriptor2, this);
    }
    get typifications() {
      return (0, _store.findAll)(this.store, 'determination/typification');
    }
    get disableSelection() {
      const filter = this.args.existsFilter;
      return !(0, _utils.isNone)(filter) && !(0, _utils.isEmpty)(filter.value);
    }
    add(value) {
      if (value) {
        this.args.filter.value.pushObject(value);
      }
    }
    remove(value) {
      this.args.filter.value.removeObject(value);
      this.dummy = null;
    }
    updateFilters(exists) {
      if (!(0, _utils.isNone)(exists)) {
        this.args.filter.value.clear();
        this.dummy = null;
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "typifications", [_object.computed], Object.getOwnPropertyDescriptor(_class.prototype, "typifications"), _class.prototype), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dummy", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "disableSelection", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "disableSelection"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "add", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "add"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "remove", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "remove"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateFilters", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateFilters"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TypificationSearchFilter);
});