define("plutof/templates/specimen/annotation/view", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "QaL168Kk",
    "block": "[[[8,[39,0],null,[[\"@icon\",\"@title\",\"@annotation\",\"@permissions\",\"@parentRoute\",\"@goBack\"],[[28,[37,1],[\"specimen\"],null],[28,[37,2],[\"Specimen.annotation.view\"],null],[30,0,[\"model\",\"annotation\"]],[30,0,[\"model\",\"permissions\"]],\"specimen\",[30,0,[\"routeActions\",\"goBack\"]]]],null],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@annotation\",\"@permissions\"],[[30,0,[\"model\",\"annotation\"]],[30,0,[\"model\",\"permissions\"]]]],null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@specimen\",\"@additionalIdentifiers\",\"@accessRights\",\"@contentType\",\"@canModifyDeterminations\"],[[30,0,[\"model\",\"specimen\"]],[30,0,[\"model\",\"additionalIdentifiers\"]],[30,0,[\"model\",\"accessRights\"]],[30,0,[\"model\",\"contentType\"]],false]],null],[1,\"\\n\\n\"],[8,[39,5],null,[[\"@object\",\"@disabledTabs\"],[[30,0,[\"model\",\"specimen\"]],[99,6,[\"@disabledTabs\"]]]],null],[1,\"\\n\"]],[],false,[\"annotation/navbar/view\",\"icon\",\"i18n-t\",\"annotation/summary\",\"specimen/view\",\"linked-items/view\",\"array\"]]",
    "moduleName": "plutof/templates/specimen/annotation/view.hbs",
    "isStrictMode": false
  });
});