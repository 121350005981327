define("plutof/templates/publishing/ena/add", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "2iEj/D/R",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"ena.add\"]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@icon\",\"@title\",\"@record\",\"@reset\",\"@goBack\",\"@infoTitle\",\"@infoContent\"],[[28,[37,2],[\"ena\"],null],[28,[37,3],[\"ena.newDataset\"],null],[30,0,[\"model\",\"ena\"]],[30,0,[\"routeActions\",\"triggerReset\"]],[30,0,[\"routeActions\",\"goBack\"]],[28,[37,3],[\"ena.title\"],null],\"information/content/ena/general\"]],null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@ena\",\"@save\",\"@validationContext\"],[[30,0,[\"model\",\"ena\"]],[30,0,[\"save\"]],[30,0,[\"validationContext\"]]]],null],[1,\"\\n\\n\"],[8,[39,5],null,[[\"@save\",\"@validationContext\",\"@goBack\"],[[30,0,[\"save\"]],[30,0,[\"validationContext\"]],[30,0,[\"routeActions\",\"goBack\"]]]],null],[1,\"\\n\"]],[],false,[\"test/route-marker\",\"navbar/edit\",\"icon\",\"i18n-t\",\"publishing/ena/edit\",\"common/save-panel\"]]",
    "moduleName": "plutof/templates/publishing/ena/add.hbs",
    "isStrictMode": false
  });
});