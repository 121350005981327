define("plutof/templates/experiment/dna/edit", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "LpNBd7UB",
    "block": "[[[10,0],[14,0,\"route-marker-dna-edit\"],[12],[13],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@icon\",\"@title\",\"@record\",\"@goBack\",\"@infoTitle\",\"@infoContent\"],[[28,[37,1],[\"experiment\"],null],[28,[37,2],[\"experiment.dna.routeTitles.edit\"],null],[30,0,[\"model\",\"dna\"]],[30,0,[\"routeActions\",\"goBack\"]],[28,[37,2],[\"experiment.general.experiments\"],null],\"information/content/dna-lab/general\"]],null],[1,\"\\n\\n\"],[1,[28,[35,3],null,[[\"dna\",\"validationContext\",\"cancel\",\"save\"],[[30,0,[\"model\",\"dna\"]],[30,0,[\"validationContext\"]],[28,[37,4],[[30,0],\"cancel\"],null],[28,[37,4],[[30,0],\"saveDNA\"],null]]]]],[1,\"\\n\"]],[],false,[\"navbar/edit\",\"icon\",\"i18n-t\",\"experiment/dna/edit-form\",\"action\"]]",
    "moduleName": "plutof/templates/experiment/dna/edit.hbs",
    "isStrictMode": false
  });
});