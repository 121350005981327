define("plutof/controllers/import/process", ["exports", "@ember/application", "@ember/controller", "@ember/object", "@ember/runloop", "@ember/service", "@ember/utils", "rsvp", "plutof/components/import/edit-rows", "plutof/components/import/utils", "plutof/controllers/import/add", "plutof/misc/abstract", "plutof/misc/config", "plutof/misc/fileupload", "plutof/utils/notifications", "plutof/utils/reflection"], function (_exports, _application, _controller, _object, _runloop, _service, _utils, _rsvp, _editRows, _utils2, _add, _abstract, _config, _fileupload, _notifications, _reflection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.executeImportProcess = executeImportProcess;
  _exports.stopAndDownloadImport = stopAndDownloadImport;
  var _dec, _class, _descriptor, _descriptor2, _class2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ImportProcessExecution = (_dec = (0, _object.computed)('process.status', 'sendingFixes'), _class = class ImportProcessExecution extends _object.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
    }
    init() {
      super.init(...arguments);
      this.set('resolutions', {});
      this.set('ignoredRows', []);
      this.set('translations', {});
    }
    updateProgress() {
      return this.process.reload();
    }
    handleErrors(errors) {
      this.set('resolutions', {});
      this.set('ignoredRows', []);
      this.set('translations', {});
      const importTable = (0, _editRows.createImportTable)(this.store, this.fields, errors, this.resolutions, this.translations, this.formats);
      this.set('importTable', importTable);
    }
    loadErrors() {
      const process = this.process;
      const process_url = (0, _reflection.get_record_url)(process);
      return this.ajax.request(`${process_url}errors/`);
    }
    sendFixes() {
      const process = this.process;
      const data = {
        rows: this.importTable.serializeRows(),
        ignored_rows: this.ignoredRows,
        resolutions: this.resolutions,
        translations: this.translations
      };
      const url = `${(0, _reflection.get_record_url)(process)}rows/`;
      return this.ajax.raw(url, {
        method: 'POST',
        data: data,
        dataType: 'json'
      }).then(successResponse => successResponse, failureResponse => this.processError(failureResponse));
    }
    processError(error) {
      const errors = error.jqXHR.responseJSON || {};
      if (error.jqXHR.status === 400 && errors.can_continue) {
        throw errors;
      } else {
        this.set('autoadvance', false);

        // TODO: Clean this up
        if (!errors.can_continue && !(0, _utils.isEmpty)(errors.error)) {
          (0, _notifications.reportError)(errors.error);
        } else if (error.payload) {
          (0, _notifications.reportError)(error.payload);
        } else {
          (0, _notifications.reportError)(error);
        }
        throw error;
      }
    }
    advance() {
      const processURL = (0, _reflection.get_record_url)(this.process);
      return this.ajax.raw(`${processURL}advance/`, {
        method: 'POST',
        dataType: 'json'
      }).catch(error => this.processError(error));
    }

    // state machine
    enterWaitingStateSync() {
      this.advance().then(() => this.updateProgress().then(() => {
        if (this.get('process.isFinished')) {
          this.enterFinishedState();
        } else {
          this.enterWaitingState();
        }
      }), error => {
        return this.updateProgress().then(() => {
          if (error.can_continue) {
            this.enterErrorState(error.rows);
          }
        });
      });
    }
    enterWaitingStateAsync() {
      if (!this.autoadvance) {
        return _abstract.EMPTY_PROMISE;
      }
      return this.updateProgress().then(() => {
        const process = this.process;
        switch (process.get('status')) {
          case 'waiting':
          case 'processing':
            if (this.autoadvance) {
              // TODO: Progressive polling interval: 2->5->10->20->60-60 sec
              (0, _runloop.later)(this, 'enterWaitingState', _config.default.Import.POLL_INTERVAL);
            }
            break;
          case 'errors':
            this.loadErrors().then(errors => this.enterErrorState(errors));
            break;
          case 'finished':
            this.enterFinishedState();
            break;
          // no default
        }
      });
    }
    enterWaitingState() {
      this.set('importTable', null);
      if (this.get('process.is_async')) {
        this.enterWaitingStateAsync();
      } else {
        this.enterWaitingStateSync();
      }
    }
    enterErrorState(errors) {
      this.handleErrors(errors);
    }
    enterFinishedState() {
      this.set('importTable', null);
    }
    enterInitialState() {
      if (!this.autoadvance) {
        return _abstract.EMPTY_PROMISE;
      }
      return this.updateProgress().then(() => {
        const status = this.get('process.status');
        switch (status) {
          case 'waiting':
            this.enterWaitingState();
            break;
          case 'processing':
            if (this.autoadvance) {
              // Even in sync import, process might be in-progress when the route is opened
              (0, _runloop.later)(this, 'enterInitialState', _config.default.Import.POLL_INTERVAL);
            }
            break;
          case 'errors':
            this.loadErrors().then(errors => this.enterErrorState(errors));
            break;
          case 'finished':
            this.enterFinishedState();
            break;
          // no default
        }
      });
    }
    errorSendFixes() {
      this.set('sendingFixes', true);
      this.sendFixes().then(result => {
        return this.updateProgress().then(process => {
          if (process.isFinished) {
            this.enterFinishedState();
          } else {
            this.enterWaitingState();
          }
        });
      }, errors => {
        return this.updateProgress().then(() => {
          if (errors.can_continue) {
            this.enterErrorState(errors.rows);
          }
        });
      }).finally(() => this.set('sendingFixes', false));
    }
    start() {
      this.set('autoadvance', true);
      this.enterInitialState();
    }
    stop() {
      this.set('autoadvance', false);
    }
    get cantUpdate() {
      return this.get('process.status') !== 'errors' || this.sendingFixes;
    }
    ignoreRow(row) {
      this.ignoredRows.pushObject(row);
      if (this.get('importTable.isEmpty')) {
        this.errorSendFixes();
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "cantUpdate", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "cantUpdate"), _class.prototype), _class);
  function executeImportProcess(process) {
    const ownerInjection = (0, _application.getOwner)(process).ownerInjection();
    const store = process.get('store');
    const treePromise = process.get('mainform').then(mainform => {
      return (0, _add.getImportTree)(store, process.get('module'), mainform);
    });
    return _rsvp.default.hash({
      process: process,
      tree: treePromise,
      fields: treePromise.then(tree => {
        const leaves = tree.get('leaves');
        return process.get('selections').map(idx => leaves[idx]);
      }),
      formats: {
        coordinates: _add.FORMATS.coordinates[process.get('format_coordinates')],
        date: _add.FORMATS.date[process.get('format_date')],
        datetime: _add.FORMATS.datetime[process.get('format_datetime')]
      }
    }).then(parts => ImportProcessExecution.create(ownerInjection, parts));
  }
  let ImportProcessController = (_class2 = class ImportProcessController extends _controller.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor3, this);
      _initializerDefineProperty(this, "confirmationDialog", _descriptor4, this);
    }
    sendFixes() {
      this.get('model.execution').errorSendFixes();
    }
    back() {
      this.routeActions.goBack();
    }
    ignoreRow(row) {
      this.get('model.execution').ignoreRow(row);
    }
    stopAndDownload() {
      const execution = this.get('model.execution');
      execution.stop();
      stopAndDownloadImport(this.ajax, execution.get('process')).then(() => this.routeActions.goBack());
    }
    async sendToClipboard() {
      await (0, _utils2.sendToClipboard)(this.confirmationDialog, this.ajax, this.model.execution.process);
      (0, _notifications.displayNotification)('success', this.i18n.t('General.done'));
    }
  }, _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "confirmationDialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "sendFixes", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "sendFixes"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "back", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "back"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "ignoreRow", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "ignoreRow"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "stopAndDownload", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "stopAndDownload"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "sendToClipboard", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "sendToClipboard"), _class2.prototype), _class2);
  function stopAndDownloadImport(ajax, process) {
    const processURL = (0, _reflection.get_record_url)(process);
    return ajax.request(`${processURL}stop/`, {
      method: 'POST'
    }).then(() => {
      (0, _fileupload.downloadFile)(processURL + 'download/');
    });
  }
  var _default = _exports.default = ImportProcessController;
});