define("plutof/templates/person/add", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "ogK0CbYP",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"person.add\"]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@icon\",\"@title\",\"@record\",\"@reset\",\"@goBack\",\"@infoContent\"],[[28,[37,2],[\"person\"],null],[28,[37,3],[\"Agents.newPerson\"],null],[30,0,[\"model\",\"person\"]],[30,0,[\"routeActions\",\"triggerReset\"]],[30,0,[\"routeActions\",\"goBack\"]],\"information/content/person/general\"]],null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@model\",\"@validationContext\",\"@givenNameRequired\"],[[30,0,[\"model\",\"person\"]],[30,0,[\"validationContext\"]],false]],null],[1,\"\\n\\n\"],[8,[39,5],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,6],null,[[\"@validationContext\"],[[30,0,[\"validationContext\"]]]],null],[1,\"\\n\\n    \"],[10,0],[14,0,\"action-buttons\"],[12],[1,\"\\n        \"],[8,[39,7],null,[[\"@save\",\"@disabled\"],[[28,[37,8],[[30,0],\"save\"],null],[30,0,[\"validationContext\",\"isInvalid\"]]]],null],[1,\"\\n\\n        \"],[8,[39,9],null,[[\"@cancel\"],[[28,[37,8],[[30,0],\"cancel\"],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"test/route-marker\",\"navbar/edit\",\"icon\",\"i18n-t\",\"agent/person/edit\",\"sticky-bottom-wrapper\",\"invalid-panels\",\"common/save-button\",\"action\",\"common/cancel-button\"]]",
    "moduleName": "plutof/templates/person/add.hbs",
    "isStrictMode": false
  });
});