define("plutof/templates/components/observation/taxon-sheet/taimeatlas-dialog", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "Walje8ZB",
    "block": "[[[6,[39,0],null,[[\"show\",\"data\",\"sheetset\",\"class\"],[[30,1],[30,2],[30,3],\"taimeatlas-sheet-dialog\"]],[[\"default\"],[[[[1,\"    \"],[1,[28,[35,1],null,[[\"sheet\",\"selection\",\"entries\",\"toggle\"],[[30,4,[\"sheet\"]],[30,4,[\"selection\"]],[30,4,[\"entries\"]],[30,4,[\"toggle\"]]]]]],[1,\"\\n\"]],[4]]]]]],[\"@show\",\"@data\",\"@sheetset\",\"sheet\"],false,[\"observation/taxon-sheet/dialog\",\"observation/taxon-sheet/taimeatlas\"]]",
    "moduleName": "plutof/templates/components/observation/taxon-sheet/taimeatlas-dialog.hbs",
    "isStrictMode": false
  });
});