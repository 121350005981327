define("plutof/components/conservation/red-list-assessment/view-permissions", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple @title={{i18n-t "General.access"}}>
      <Access::ViewRightsBase as |vr|>
          <vr.panel>
              {{#resolve-promise @assessment.red_list as |redlist|}}
                  {{#if redlist}}
                      <vr.derivedFrom @parent={{hash
                          type="redbook/red-list"
                          id=redlist.id
                          name=redlist.representation
                      }} />
  
                      <vr.view.public />
  
                      {{#resolve-promise redlist.workgroup as |workgroup|}}
                          <vr.edit.selected @groups={{array workgroup}} />
                      {{/resolve-promise}}
                  {{else}}
                      <vr.view.public />
                  {{/if}}
              {{/resolve-promise}}
          </vr.panel>
  
          <vr.panel>
              <vr.history
                  @createdBy="plutof"
                  @createdAt={{@assessment.created}}
                  @updatedBy={{@assessment.modified_by.representation}}
                  @updatedAt={{@assessment.modified}} />
          </vr.panel>
      </Access::ViewRightsBase>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "fdyKdf+m",
    "block": "[[[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"General.access\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[30,1,[\"panel\"]],null,null,[[\"default\"],[[[[1,\"\\n\"],[6,[39,3],[[30,2,[\"red_list\"]]],null,[[\"default\"],[[[[41,[30,3],[[[1,\"                    \"],[8,[30,1,[\"derivedFrom\"]],null,[[\"@parent\"],[[28,[37,5],null,[[\"type\",\"id\",\"name\"],[\"redbook/red-list\",[30,3,[\"id\"]],[30,3,[\"representation\"]]]]]]],null],[1,\"\\n\\n                    \"],[8,[30,1,[\"view\",\"public\"]],null,null,null],[1,\"\\n\\n\"],[6,[39,3],[[30,3,[\"workgroup\"]]],null,[[\"default\"],[[[[1,\"                        \"],[8,[30,1,[\"edit\",\"selected\"]],null,[[\"@groups\"],[[28,[37,6],[[30,4]],null]]],null],[1,\"\\n\"]],[4]]]]]],[]],[[[1,\"                    \"],[8,[30,1,[\"view\",\"public\"]],null,null,null],[1,\"\\n\"]],[]]]],[3]]]]],[1,\"        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,1,[\"panel\"]],null,null,[[\"default\"],[[[[1,\"\\n            \"],[8,[30,1,[\"history\"]],null,[[\"@createdBy\",\"@createdAt\",\"@updatedBy\",\"@updatedAt\"],[\"plutof\",[30,2,[\"created\"]],[30,2,[\"modified_by\",\"representation\"]],[30,2,[\"modified\"]]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[1]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"vr\",\"@assessment\",\"redlist\",\"workgroup\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"access/view-rights-base\",\"resolve-promise\",\"if\",\"hash\",\"array\"]]",
    "moduleName": "plutof/components/conservation/red-list-assessment/view-permissions.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});