define("plutof/templates/photobank/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "tLRl99At",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"photobank.index\"]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@icon\",\"@title\",\"@addRoute\",\"@pagination\",\"@infoTitle\",\"@goBack\"],[[28,[37,2],[\"photobank\"],null],[28,[37,3],[\"Photobank.listTitle\"],null],\"photobank.add\",[30,0,[\"model\",\"pagination\"]],[28,[37,3],[\"Photobank.infoTitle\"],null],[30,0,[\"routeActions\",\"goBack\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"primaryControls\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[30,1,[\"upload\"]],null,[[\"@use\"],[[30,0,[\"addFromFiles\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,3],[\"General.Upload\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[1,2]]]]],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@records\"],[[30,0,[\"model\",\"pagination\",\"objects\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,3],null,[[\"@label\"],[[28,[37,3],[\"Photobank.colID\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,5],[[24,\"classNames\",\"list-view__row__view-link\"]],[[\"@route\",\"@model\"],[\"photobank.view\",[30,4,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,4,[\"object_idprim\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,3],null,[[\"@label\"],[[28,[37,3],[\"Photobank.colCategory\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,4,[\"categoryName\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,5,[\"modified\"]],null,null,null],[1,\"\\n    \"],[8,[30,5,[\"relatedObjects\"]],null,null,null],[1,\"\\n    \"],[8,[30,5,[\"clipboard\"]],null,[[\"@contentType\"],[[30,0,[\"model\",\"contentType\"]]]],null],[1,\"\\n    \"],[8,[30,5,[\"access\"]],null,null,null],[1,\"\\n\"]],[3,4,5]]]]],[1,\"\\n\"]],[\"navbar\",\"slots\",\"Field\",\"object\",\"common\"],false,[\"test/route-marker\",\"navbar/list\",\"icon\",\"i18n-t\",\"tables/list-route\",\"link-to\"]]",
    "moduleName": "plutof/templates/photobank/index.hbs",
    "isStrictMode": false
  });
});