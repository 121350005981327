define("plutof/components/observation/moderation/gallery", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="gallery">
      {{#each @entries as |entry|}}
          <Observation::Moderation::Gallery::Thumbnail
              @record={{entry.observation}}
              @selected={{entry.selected}}
              @accept={{fn this._accept entry.observation.id}}
              @reject={{fn @reject entry.observation}}
              @forward={{fn @prepareForwarding (array entry.observation)}}
              @toggleModal={{fn @toggleModal entry.observation}} />
      {{/each}}
  </div>
  
  */
  {
    "id": "0kbi3qd+",
    "block": "[[[10,0],[14,0,\"gallery\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,\"        \"],[8,[39,2],null,[[\"@record\",\"@selected\",\"@accept\",\"@reject\",\"@forward\",\"@toggleModal\"],[[30,2,[\"observation\"]],[30,2,[\"selected\"]],[28,[37,3],[[30,0,[\"_accept\"]],[30,2,[\"observation\",\"id\"]]],null],[28,[37,3],[[30,3],[30,2,[\"observation\"]]],null],[28,[37,3],[[30,4],[28,[37,4],[[30,2,[\"observation\"]]],null]],null],[28,[37,3],[[30,5],[30,2,[\"observation\"]]],null]]],null],[1,\"\\n\"]],[2]],null],[13],[1,\"\\n\"]],[\"@entries\",\"entry\",\"@reject\",\"@prepareForwarding\",\"@toggleModal\"],false,[\"each\",\"-track-array\",\"observation/moderation/gallery/thumbnail\",\"fn\",\"array\"]]",
    "moduleName": "plutof/components/observation/moderation/gallery.hbs",
    "isStrictMode": false
  });
  let ObservationModerationGallery = (_class = class ObservationModerationGallery extends _component2.default {
    _accept(id) {
      return this.args.accept(id);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "_accept", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_accept"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ObservationModerationGallery);
});