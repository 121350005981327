define("plutof/templates/glossary/add", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "hiFs33j1",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"glossary.add\"]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@icon\",\"@title\",\"@record\",\"@reset\",\"@goBack\",\"@infoTitle\",\"@infoContent\"],[[28,[37,2],[\"glossary\"],null],[28,[37,3],[\"Glossary.newGlossary\"],null],[30,0,[\"model\",\"glossary\"]],[30,0,[\"routeActions\",\"triggerReset\"]],[30,0,[\"routeActions\",\"goBack\"]],[28,[37,3],[\"Glossary.infoTitle\"],null],\"information/content/general/info-empty\"]],null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@glossary\",\"@accessRights\",\"@validationContext\",\"@thesauri\",\"@relatedThesauri\",\"@objectsToDelete\"],[[30,0,[\"model\",\"glossary\"]],[30,0,[\"model\",\"accessRights\"]],[30,0,[\"validationContext\"]],[30,0,[\"model\",\"thesauri\"]],[30,0,[\"model\",\"relatedThesauri\"]],[30,0,[\"thesauriMarkedForDeletion\"]]]],null],[1,\"\\n\\n\"],[8,[39,5],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,6],null,[[\"@validationContext\"],[[30,0,[\"validationContext\"]]]],null],[1,\"\\n\\n    \"],[10,0],[14,0,\"action-buttons\"],[12],[1,\"\\n        \"],[8,[39,7],null,[[\"@save\",\"@disabled\"],[[30,0,[\"save\"]],[30,0,[\"validationContext\",\"isInvalid\"]]]],null],[1,\"\\n\\n        \"],[8,[39,8],null,[[\"@cancel\"],[[30,0,[\"routeActions\",\"goBack\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"test/route-marker\",\"navbar/edit\",\"icon\",\"i18n-t\",\"glossary/edit\",\"sticky-bottom-wrapper\",\"invalid-panels\",\"common/save-button\",\"common/cancel-button\"]]",
    "moduleName": "plutof/templates/glossary/add.hbs",
    "isStrictMode": false
  });
});