define("plutof/templates/dmp/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "OeZwnU8J",
    "block": "[[[8,[39,0],null,[[\"@icon\",\"@title\",\"@addRoute\",\"@goBack\",\"@pagination\",\"@infoTitle\",\"@infoContent\"],[[28,[37,1],[\"dmp\"],null],[28,[37,2],[\"dmp.routeTitles.list\"],null],\"dmp.add\",[30,0,[\"routeActions\",\"goBack\"]],[30,0,[\"model\",\"pagination\"]],[28,[37,2],[\"dmp.general.dmp\"],null],\"information/content/dmp/general\"]],null],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@records\"],[[30,0,[\"model\",\"pagination\",\"objects\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,1],null,[[\"@label\"],[[28,[37,2],[\"PlutofListViewColumn.title\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,4],null,[[\"@route\",\"@model\"],[\"dmp.view\",[30,2,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,2,[\"representation\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,3,[\"modified\"]],null,null,null],[1,\"\\n    \"],[8,[30,3,[\"access\"]],null,null,null],[1,\"\\n\"]],[1,2,3]]]]],[1,\"\\n\"]],[\"Field\",\"dmp\",\"common\"],false,[\"navbar/list\",\"icon\",\"i18n-t\",\"tables/list-route\",\"link-to\"]]",
    "moduleName": "plutof/templates/dmp/index.hbs",
    "isStrictMode": false
  });
});