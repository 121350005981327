define("plutof/templates/publishing/gbif/add", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "Fh8+t5r2",
    "block": "[[[10,0],[14,0,\"route-marker-publishing-add\"],[12],[13],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@icon\",\"@title\",\"@record\",\"@reset\",\"@goBack\",\"@infoContent\"],[[28,[37,1],[\"gbif\"],null],[28,[37,2],[\"dataResource.general.newDataset\"],null],[30,0,[\"model\",\"dataset\"]],[30,0,[\"routeActions\",\"triggerReset\"]],[30,0,[\"routeActions\",\"goBack\"]],\"information/content/publishing/gbif/general\"]],null],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@dataset\",\"@sequences\",\"@specimens\",\"@observations\",\"@referencebased\",\"@agents\",\"@rights\",\"@validationContext\",\"@cancel\",\"@save\",\"@setLogo\"],[[30,0,[\"model\",\"dataset\"]],[30,0,[\"model\",\"sequences\"]],[30,0,[\"model\",\"specimens\"]],[30,0,[\"model\",\"observations\"]],[30,0,[\"model\",\"referencebased\"]],[30,0,[\"model\",\"agents\"]],[30,0,[\"model\",\"rights\"]],[30,0,[\"validationContext\"]],[28,[37,4],[[30,0],\"cancel\"],null],[30,0,[\"saveDataset\"]],[30,0,[\"setLogo\"]]]],null],[1,\"\\n\"]],[],false,[\"navbar/edit\",\"icon\",\"i18n-t\",\"publishing/gbif/edit-form\",\"action\"]]",
    "moduleName": "plutof/templates/publishing/gbif/add.hbs",
    "isStrictMode": false
  });
});