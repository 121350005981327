define("plutof/templates/treatment/edit", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "8CT/ggvT",
    "block": "[[[10,0],[14,0,\"route-marker-treatment-edit\"],[12],[13],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@icon\",\"@title\",\"@goBack\"],[[28,[37,1],[\"treatment\"],null],[28,[37,2],[\"CollectionManagement.editTreatment\"],null],[30,0,[\"routeActions\",\"goBack\"]]]],null],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@model\",\"@validationContext\"],[[30,0,[\"model\",\"treatment\"]],[30,0,[\"validationContext\"]]]],null],[1,\"\\n\\n\"],[8,[39,4],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,5],null,[[\"@validationContext\"],[[30,0,[\"validationContext\"]]]],null],[1,\"\\n\\n    \"],[10,0],[14,0,\"action-buttons\"],[12],[1,\"\\n        \"],[8,[39,6],null,[[\"@save\",\"@disabled\"],[[30,0,[\"save\"]],[30,0,[\"validationContext\",\"isInvalid\"]]]],null],[1,\"\\n\\n        \"],[8,[39,7],null,[[\"@cancel\"],[[30,0,[\"cancel\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"navbar/edit\",\"icon\",\"i18n-t\",\"collection-lab/treatment/edit\",\"sticky-bottom-wrapper\",\"invalid-panels\",\"common/save-button\",\"common/cancel-button\"]]",
    "moduleName": "plutof/templates/treatment/edit.hbs",
    "isStrictMode": false
  });
});