define("plutof/templates/components/import/process-list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "Sbm8s4JQ",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"table-responsive\"],[12],[1,\"\\n        \"],[10,\"table\"],[14,0,\"plutof-table table-hover\"],[12],[1,\"\\n            \"],[10,\"thead\"],[12],[1,\"\\n                \"],[10,\"tr\"],[12],[1,\"\\n                    \"],[10,\"th\"],[12],[1,[28,[35,1],[\"Search.module\"],null]],[13],[1,\"\\n                    \"],[10,\"th\"],[12],[1,[28,[35,1],[\"FileRepository.file\"],null]],[13],[1,\"\\n                    \"],[10,\"th\"],[12],[1,[28,[35,1],[\"PlutofListViewColumn.status\"],null]],[13],[1,\"\\n                    \"],[10,\"th\"],[12],[1,[28,[35,1],[\"Import.progress.progress\"],null]],[13],[1,\"\\n                    \"],[10,\"th\"],[12],[1,[28,[35,1],[\"PlutofListViewColumn.modified\"],null]],[13],[1,\"\\n                    \"],[10,\"th\"],[12],[1,[28,[35,1],[\"UniteSH.actions\"],null]],[13],[1,\"\\n                \"],[13],[1,\"\\n            \"],[13],[1,\"\\n\\n            \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,1]],null]],null],null,[[[1,\"                    \"],[8,[39,4],null,[[\"@process\",\"@stop\",\"@sendToClipboard\",\"@download\",\"@delete\"],[[30,2],[28,[37,5],[[30,3],[30,2]],null],[28,[37,5],[[30,0,[\"sendToClipboard\"]],[30,2]],null],[28,[37,5],[[30,4],[30,2]],null],[28,[37,5],[[30,5],[30,2]],null]]],null],[1,\"\\n\"]],[2]],null],[1,\"            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@processes\",\"process\",\"@stop\",\"@download\",\"@delete\"],false,[\"plutof-panel/headerless\",\"i18n-t\",\"each\",\"-track-array\",\"import/process-list/row\",\"fn\"]]",
    "moduleName": "plutof/templates/components/import/process-list.hbs",
    "isStrictMode": false
  });
});