define("plutof/templates/annotations/created", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "KUFujIkL",
    "block": "[[[8,[39,0],null,[[\"@icon\",\"@title\",\"@goBack\",\"@pagination\"],[[28,[37,1],[\"annotation\"],null],[28,[37,2],[\"annotation.routes.created.title\"],null],[30,0,[\"routeActions\",\"goBack\"]],[30,0,[\"model\",\"annotations\"]]]],null],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@model\"],[\"annotation.annotation\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,4],null,[[\"@records\"],[[30,0,[\"model\",\"annotations\",\"objects\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,5],null,[[\"@field\",\"@annotation\",\"@translations\"],[[30,2],[30,3],[30,1]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[30,4,[\"icon\"]],null,null,null],[1,\"\\n            \"],[8,[30,4,[\"link\"]],null,null,null],[1,\"\\n            \"],[8,[30,4,[\"status\"]],null,null,null],[1,\"\\n            \"],[8,[30,4,[\"moderator\"]],null,null,null],[1,\"\\n            \"],[8,[30,4,[\"comment\"]],null,null,null],[1,\"\\n            \"],[8,[30,4,[\"annotatedAt\"]],null,null,null],[1,\"\\n        \"]],[4]]]]],[1,\"\\n    \"]],[2,3]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"translations\",\"Field\",\"annotation\",\"fields\"],false,[\"navbar/list\",\"icon\",\"i18n-t\",\"i18n/model-strings\",\"tables/list-route\",\"annotation/fields\"]]",
    "moduleName": "plutof/templates/annotations/created.hbs",
    "isStrictMode": false
  });
});