define("plutof/routes/application", ["exports", "@ember/routing/route", "@ember/service", "plutof/mixins/route", "plutof/utils/cookies", "plutof/utils/i18n", "plutof/utils/notifications"], function (_exports, _route, _service, _route2, _cookies, _i18n, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ApplicationRoute = (_class = class ApplicationRoute extends _route.default.extend(_route2.default) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "emberDataStore", _descriptor, this);
      _initializerDefineProperty(this, "session", _descriptor2, this);
      _initializerDefineProperty(this, "settings", _descriptor3, this);
      _defineProperty(this, "title", 'General.pageTitle');
    }
    beforeModel(transition) {
      super.beforeModel(...arguments);
      if (!this.session.isAuthenticated) {
        this.setupUrlLocale();
      }
    }
    setupController(controller) {
      var model = {
        messages: (0, _notifications.getNotifications)()
      };
      super.setupController(controller, model);
    }
    setupUrlLocale() {
      const urlParams = new URLSearchParams(window.location.search);
      const urlLocale = urlParams.get('language');
      const currentLocale = (0, _cookies.getCookie)('language');

      // Assumes language value is ISO 639-2.
      if (_i18n.LANG_CODES.includes(urlLocale) && currentLocale !== urlLocale) {
        (0, _cookies.setCookie)('language', urlLocale);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "emberDataStore", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "settings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  var _default = _exports.default = ApplicationRoute;
});