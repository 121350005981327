define("plutof/templates/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "T61Xbhsd",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"index\"]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@kind\"],[\"view\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"header\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[30,1,[\"header\"]],null,[[\"@icon\",\"@title\"],[[28,[37,2],[\"person\"],null],[28,[37,3],[\"Agents.person.person\"],null]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,2,[\"primaryControls\"]],null,null,null],[1,\"\\n\\n\"],[1,\"    \"],[8,[30,2,[\"secondaryControls\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[30,1,[\"button\"]],null,[[\"@clicked\",\"@icon\"],[[30,0,[\"edit\"]],[28,[37,2],[\"edit\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,3],[\"General.Edit\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,1,[\"bookmark\"]],null,null,null],[1,\"\\n\\n        \"],[8,[30,1,[\"info\"]],null,[[\"@title\",\"@content\"],[[28,[37,3],[\"Agents.person.person\"],null],\"information/content/person/general\"]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[1,2]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"data\",\"isFulfilled\"]],[[[1,\"    \"],[1,[28,[35,5],null,[[\"person\"],[[30,0,[\"data\",\"person\"]]]]]],[1,\"\\n\\n    \"],[1,[28,[35,6],null,[[\"agentassociations\"],[[30,0,[\"data\",\"agentassociations\"]]]]]],[1,\"\\n\\n    \"],[1,[28,[35,7],null,[[\"person\",\"statistics\",\"statisticsOptions\"],[[30,0,[\"data\",\"person\"]],[30,0,[\"data\",\"statistics\"]],[30,0,[\"data\",\"statisticsOptions\"]]]]]],[1,\"\\n\"]],[]],null]],[\"navbar\",\"slots\"],false,[\"test/route-marker\",\"navbar/navbar-base\",\"icon\",\"i18n-t\",\"if\",\"person/person-view-general\",\"person/person-view-associations\",\"person/person-view-statistics\"]]",
    "moduleName": "plutof/templates/index.hbs",
    "isStrictMode": false
  });
});