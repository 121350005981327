define("plutof/templates/components/publishing/gbif/project-form", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "4oLvZLju",
    "block": "[[[8,[39,0],null,[[\"@columns\"],[3]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[12],[1,\"\\n        \"],[1,[28,[35,1],null,[[\"path\",\"source\"],[\"dataResource.project.title\",\"local\"]]]],[1,\"\\n        \"],[1,[28,[35,2],null,[[\"type\",\"value\",\"class\"],[\"text\",[30,1,[\"project_title\"]],\"form-control\"]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[12],[1,\"\\n        \"],[1,[28,[35,1],null,[[\"path\",\"source\",\"tooltipText\"],[\"dataResource.project.abstract\",\"local\",[28,[37,3],[\"dataResource.tooltips.projectAbstract\"],null]]]]],[1,\"\\n        \"],[8,[39,4],[[24,0,\"form-control\"]],[[\"@value\"],[[30,1,[\"project_abstract\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[12],[1,\"\\n        \"],[1,[28,[35,1],null,[[\"path\",\"source\",\"tooltipText\"],[\"dataResource.project.funding\",\"local\",[28,[37,3],[\"dataResource.tooltips.projectFunding\"],null]]]]],[1,\"\\n        \"],[8,[39,4],[[24,0,\"form-control\"]],[[\"@value\"],[[30,1,[\"project_funding\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@dataset\"],false,[\"layout/regular-grid\",\"plutof-labelc\",\"input\",\"i18n-t\",\"flexible-textarea\"]]",
    "moduleName": "plutof/templates/components/publishing/gbif/project-form.hbs",
    "isStrictMode": false
  });
});