define("plutof/templates/conservation-lab/red-list-assessment/view", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "uIOzeWKN",
    "block": "[[[8,[39,0],null,[[\"@icon\",\"@title\",\"@record\",\"@module\",\"@editRoute\",\"@goBack\",\"@deletable\",\"@permissions\"],[[28,[37,1],[\"conservation\"],null],[28,[37,2],[\"conservation.redListAssessment.view.title\"],null],[30,0,[\"model\",\"assessment\"]],\"redbook/redlistassessment\",\"conservation-lab.red-list-assessment.edit\",[30,0,[\"routeActions\",\"goBack\"]],true,[30,0,[\"model\",\"permissions\"]]]],null],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@assessment\"],[[30,0,[\"model\",\"assessment\"]]]],null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@object\",\"@mainform\"],[[30,0,[\"model\",\"assessment\"]],[30,0,[\"model\",\"assessment\",\"red_list\",\"form\"]]]],null],[1,\"\\n\\n\"],[8,[39,5],null,[[\"@assessment\"],[[30,0,[\"model\",\"assessment\"]]]],null],[1,\"\\n\"]],[],false,[\"navbar/view\",\"icon\",\"i18n-t\",\"conservation/red-list-assessment/view\",\"measurements/measurement-view/panel\",\"conservation/red-list-assessment/view-permissions\"]]",
    "moduleName": "plutof/templates/conservation-lab/red-list-assessment/view.hbs",
    "isStrictMode": false
  });
});