define("plutof/utils/cloning", ["exports", "@ember/application", "@ember/object/mixin", "@ember/runloop", "@ember/utils", "rsvp", "plutof/components/edit-determinations", "plutof/components/edit-preparations", "plutof/components/linked-items/edit", "plutof/components/measurements/measurement-form", "plutof/components/sample/sampling-area-event", "plutof/misc/abstract", "plutof/misc/content_types", "plutof/misc/measurements", "plutof/utils/i18n", "plutof/utils/loading", "plutof/utils/model", "plutof/utils/notifications", "plutof/views/sample/event"], function (_exports, _application, _mixin, _runloop, _utils, _rsvp, _editDeterminations, _editPreparations, _edit, _measurementForm, _samplingAreaEvent, _abstract, _content_types, _measurements, _i18n, _loading, _model, _notifications, _event) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ControllerCloningMixin = void 0;
  _exports.clone_determination_data = clone_determination_data;
  _exports.clone_linked_items = clone_linked_items;
  _exports.clone_measurements = clone_measurements;
  _exports.clone_occurrence = clone_occurrence;
  _exports.clone_preparation_data = clone_preparation_data;
  _exports.clone_record = clone_record;
  _exports.clone_relation = clone_relation;
  _exports.clone_sampling_data = clone_sampling_data;
  var i18n = (0, _i18n.getI18n)();

  // Creates a new record using the passed one as a template
  // NB: Requires model annotations
  // BTW: Can use same annotations for normalized serialization
  function clone_record(store, record) {
    if ((0, _utils.isNone)(record)) {
      return _rsvp.default.Promise.resolve(record);
    }
    var model = record.constructor.modelName;
    var clone = store.createRecord(model, {});
    return _rsvp.default.Promise.resolve(record).then(function () {
      record.eachAttribute(function (name, meta) {
        clone.set(name, record.get(name));
      });
      var relationship_ps = [];
      record.eachRelationship(function (name, meta) {
        relationship_ps.push(record.get(name).then(function (value) {
          if (!(0, _utils.isNone)(value)) {
            if (meta.kind === 'belongsTo') {
              if (meta.options.own) {
                return clone_record(store, value).then(function (relatedClone) {
                  clone.set(name, relatedClone);
                });
              } else {
                clone.set(name, value);
              }
            } else {
              // TODO
            }
          }
          return _abstract.EMPTY_PROMISE;
        }, error => {
          // Ignore fetch error
        }));
      });

      // TODO: extras, declared in model
      if (!(0, _utils.isNone)(record.cloneExtras)) {
        relationship_ps.push(record.cloneExtras(clone));
      }
      return _rsvp.default.all(relationship_ps).then(function () {
        return clone;
      });
    });
  }
  function clone_relation(store, request, clone_param, clone) {
    return request.then(function (src_objs) {
      return _rsvp.default.all(src_objs.map(clone_record.bind(undefined, store))).then(function (obj_clones) {
        obj_clones.invoke('set', clone_param, clone);
        return obj_clones;
      });
    });
  }

  // samplingData :: SamplingAreaEventFormData
  async function clone_sampling_data(store, samplingData, cloneArea = true, cloneEvent = true) {
    const project = samplingData.project;
    const areaTemplate = await samplingData.area;
    let area, areaMeasurements;
    if (cloneArea) {
      area = await clone_record(store, areaTemplate).then(areaClone => {
        let newArea = areaClone;
        newArea.name = null;

        // we do not allow copying sites
        if (newArea.site) {
          (0, _notifications.displayNotification)('warning', i18n.t('warnings.canNotCloneSite'), null, 20000);
          newArea = store.createRecord('sample/samplingarea');
        }

        /*
        * When cloning areas (from cloning taxon occurrences), we want the
        * occurrence's project to be the cloned area's project, regardless
        * of what the area's project was initially.
        */
        newArea.study = project;
        return newArea;
      });
      areaMeasurements = clone_measurements(store, area, null, areaTemplate);
    } else {
      area = areaTemplate;
      areaMeasurements = samplingData.areaMeasurements;
    }
    let eventData;
    if (cloneEvent) {
      eventData = (0, _event.get_sampling_event_form_data)(store, samplingData.event).then(data => {
        const eventTemplate = data.event;
        return clone_record(store, eventTemplate).then(eventClone => {
          const eventMeasurementsPromise = clone_measurements(store, eventClone, null, eventTemplate);
          const habitatMeasurementsPromise = _rsvp.default.hash({
            template: eventTemplate.habitat,
            clone: eventClone.habitat
          }).then(habitats => {
            return clone_measurements(store, habitats.clone, null, habitats.template);
          });
          return _rsvp.default.hash({
            event: eventClone,
            gatheringAgents: data.gatheringAgents,
            eventMeasurements: eventMeasurementsPromise,
            habitatMeasurements: habitatMeasurementsPromise
          }).then(fields => _event.SamplingEventFormData.create(fields));
        });
      });
    } else {
      eventData = (0, _event.get_sampling_event_form_data)(store, samplingData.event);
    }
    return _rsvp.default.hash({
      area,
      areaMeasurements,
      eventData,
      project
    }).then(parts => {
      parts.eventData.event.samplingarea = parts.area;
      return (0, _samplingAreaEvent.createSamplingData)({
        store,
        eventData: parts.eventData,
        area: parts.area,
        areaMeasurements: parts.areaMeasurements,
        project: parts.project
      });
    });
  }
  function clone_determination_data(store, sourceData, newTarget) {
    const dataPromise = (0, _editDeterminations.createDeterminationData)(store, newTarget, {
      requireOne: sourceData.get('requireOne'),
      prefill: false
    });
    const determinationsPromise = _rsvp.default.all(sourceData.get('entries').filterBy('determination.is_current').map(entry => {
      return _rsvp.default.all([clone_record(store, entry.get('determination')), _rsvp.default.all(entry.get('determiners').map(determiner => clone_record(store, determiner)))]).then(([determination, determiners]) => {
        // Ugh. TODO: At least define constants
        determination.set('can_edit', 3);
        determiners.forEach(determiner => determiner.set('determination', determination));
        return {
          determination,
          determiners
        };
      });
    }));
    return _rsvp.default.all([dataPromise, determinationsPromise]).then(([data, determinations]) => {
      for (let {
        determination,
        determiners
      } of determinations) {
        data.addExisting(determination, determiners, {
          toEnd: true
        });
      }
      return data;
    });
  }
  function clone_preparation_data(store, sourceData, newTarget) {
    return _rsvp.default.all(sourceData.get('preparations').map(function (preparation) {
      return clone_record(store, preparation).then(function (preparationClone) {
        return _rsvp.default.all(preparation.get('agents').map(function (agent) {
          return clone_record(store, agent).then(function (agentClone) {
            agentClone.set('preparation', preparationClone);
            return agentClone;
          });
        })).then(function (agents) {
          preparationClone.set('object', newTarget);
          preparationClone.set('agents', agents);
          return preparationClone;
        });
      });
    })).then(function (preparations) {
      // XXX
      preparations.forEach(prep => {
        prep.set('fadeId', Math.random());
      });
      const ownerInjection = (0, _application.getOwner)(store).ownerInjection();

      // XXX: see clone_determination_data
      return _editPreparations.PreparationsData.create(ownerInjection, {
        targetRecord: newTarget,
        preparations: preparations
      });
    });
  }
  function clone_measurements(store, clone, mainform, template) {
    // In order to have values for measurements we need to pass "old template" (specimen with id)
    // And then re-write object later with the actual new "clone"
    return (0, _measurements.get_object_form_measurements)(store, template, mainform).then(oms => {
      const renewedClones = oms.map(om => {
        om.setProperties({
          'object': clone,
          'id': null
        });
        return om;
      });
      return _measurementForm.MeasurementsData.create({
        objectMeasurements: renewedClones,
        object: clone,
        store: store
      });
    });
  }

  // TODO: multiple-boolean signature is meh
  function clone_linked_items(store, template, clone, useEventFiles = false, multiple = false) {
    let itemDataPromise = multiple ? (0, _edit.createMultipleLinkedItemsData)(store, {
      useEventFiles
    }) : (0, _edit.createLinkedItemsData)(store, clone, {
      useEventFiles
    });
    return itemDataPromise.then(itemData => {
      return (0, _content_types.get_object_ctype)(store, template).then(ctype => {
        const queryParams = {
          content_type: ctype.get('id'),
          object_id: template.get('id')
        };
        function load(model, properties) {
          return (0, _loading.loadAll)(store, model, queryParams).then(items => {
            return _rsvp.default.all(items.map(item => {
              return _rsvp.default.hash(item.getProperties(properties));
            }));
          });
        }
        return _rsvp.default.hash({
          files: load('filerepository/item', ['file']),
          references: load('reference/item', ['reference', 'pages']),
          glossaries: load('glossary/item', ['glossary']),
          links: load('reference/externallink', ['link', 'description'])
        }).then(templateItems => {
          Object.keys(templateItems).forEach(itemType => {
            const itemStore = itemData.get(itemType);
            templateItems[itemType].forEach(item => itemStore.add(item));
          });
          return itemData;
        });
      });
    });
  }
  function clone_occurrence(store, modelname, template_id) {
    return store.findRecord(modelname, template_id).then(template => {
      return (0, _model.getOccurrenceSamplingData)(store, template).then(samplingData => {
        return clone_record(store, template).then(clone => {
          return {
            occurrence: clone,
            formData: samplingData.formData
          };
        });
      });
    });
  }

  // Requires: save :: () => Promise (), query params clone_area & template_id,
  // doRefresh action on this route, primaryOccurrence :: TaxonOccurrence
  const ControllerCloningMixin = _exports.ControllerCloningMixin = _mixin.default.create({
    actions: {
      clone: function (cloneArea, cloneEvent) {
        this.set('cloning', true);
        return this._save().then(() => {
          this.set('closePanels', true);
          const templateID = this.get('primaryOccurrence.id');
          this.set('template_id', templateID);
          this.set('clone_area', cloneArea);
          this.set('clone_event', cloneEvent);
          this.set('cloning', false);
          this.set('routeHasBeenLoaded', false); // XXX

          (0, _runloop.later)(() => {
            this.routeActions.refresh();
          });
        }, _notifications.reportError);
      }
    }
  });
});