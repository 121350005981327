define("plutof/controllers/experiment/dna-extraction/edit", ["exports", "@ember/controller", "@ember/object", "@ember/utils", "rsvp", "plutof/components/experiment/utils", "plutof/mixins/edit-controller", "plutof/utils/notifications"], function (_exports, _controller, _object, _utils, _rsvp, _utils2, _editController, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function hasSource(dna) {
    return [(0, _object.get)(dna.guardedSpecimen, 'id'), (0, _object.get)(dna.livingSpecimen, 'id'), (0, _object.get)(dna.materialSample, 'id')].any(_utils.isPresent);
  }
  let DNAExtractionEditController = (_class = class DNAExtractionEditController extends _controller.default.extend(_editController.default) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "queryParams", ['specimens', 'livingspecimens', 'materialsamples']);
    }
    async save() {
      await this.model.experiment.save();

      // XXX
      this.model.materials.forEach(material => {
        (0, _object.set)(material, 'dna_extraction', this.model.experiment);
      });
      const changed = (0, _utils2.filterChangedMaterials)(this.model.materials, this.model.initialMaterialState);
      const materialsWithoutEditPermission = [];
      await _rsvp.default.all(changed.filter(hasSource).map(async material => {
        try {
          await material.save();
        } catch (err) {
          if (err.isAdapterError && err.errors && err.errors[0].status === '403') {
            materialsWithoutEditPermission.push(material);
            material.rollbackAttributes();
          } else {
            throw err;
          }
        }
      }));
      await _rsvp.default.all(this.model.materials.filter(m => !hasSource(m) && !m.isNew).invoke('destroyRecord'));
      await this.model.linkedItemsData.save(this.model.experiment, this.model.accessRights);
      return {
        materialsWithoutEditPermission
      };
    }
    saveExperiment() {
      (0, _notifications.clearNotifications)();
      (0, _notifications.displayNotification)('status', this.i18n.t('General.saving'));
      return this.save().then(({
        materialsWithoutEditPermission
      }) => {
        (0, _notifications.displayNotification)('success', this.i18n.t('General.done'));
        if (materialsWithoutEditPermission.length > 0) {
          (0, _notifications.displayNotification)('warning', this.i18n.t('experiment.extraction.errors.dnaMissingPermissions'));
        }
        this.clearParams();
        this.set('routeHasBeenLoaded', false);
        this.routeActions.goBack();
      }).catch(_notifications.reportError);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "saveExperiment", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveExperiment"), _class.prototype), _class);
  var _default = _exports.default = DNAExtractionEditController;
});