define("plutof/templates/conservation-lab/red-list/view", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "ko/eny1x",
    "block": "[[[8,[39,0],null,[[\"@icon\",\"@title\",\"@record\",\"@module\",\"@editRoute\",\"@deletable\",\"@goBack\",\"@permissions\"],[[28,[37,1],[\"conservation\"],null],[28,[37,2],[\"conservation.redList.view.title\"],null],[30,0,[\"model\",\"redlist\"]],\"redbook/redlist\",\"conservation-lab.red-list.edit\",true,[30,0,[\"routeActions\",\"goBack\"]],[30,0,[\"model\",\"permissions\"]]]],null],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@redlist\"],[[30,0,[\"model\",\"redlist\"]]]],null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@redlist\",\"@assessments\",\"@canModify\",\"@add\"],[[30,0,[\"model\",\"redlist\"]],[30,0,[\"model\",\"assessments\"]],[30,0,[\"model\",\"permissions\",\"canModify\"]],[30,0,[\"addAssessment\"]]]],null],[1,\"\\n\\n\"],[8,[39,5],null,[[\"@workgroupId\"],[[30,0,[\"model\",\"workgroup\",\"id\"]]]],null],[1,\"\\n\\n\"],[8,[39,6],null,[[\"@disabledTabs\",\"@object\"],[[28,[37,7],[\"glossaries\",\"links\",\"references\",\"permits\"],null],[30,0,[\"model\",\"redlist\"]]]],null],[1,\"\\n\\n\"],[8,[39,8],null,[[\"@redlist\",\"@workgroup\"],[[30,0,[\"model\",\"redlist\"]],[30,0,[\"model\",\"workgroup\"]]]],null],[1,\"\\n\"]],[],false,[\"navbar/view\",\"icon\",\"i18n-t\",\"conservation/red-list/view/fields\",\"conservation/red-list/view/assessments\",\"profile/groups/managing-group/view\",\"linked-items/view\",\"array\",\"conservation/red-list/view/permissions\"]]",
    "moduleName": "plutof/templates/conservation-lab/red-list/view.hbs",
    "isStrictMode": false
  });
});