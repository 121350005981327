define("plutof/templates/compound/view", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "TOSNXo7w",
    "block": "[[[10,0],[14,0,\"route-marker-compound-view\"],[12],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"fullscreen-route\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@icon\",\"@title\",\"@permissions\",\"@goBack\"],[[28,[37,1],[\"unite-compound-cluster\"],null],[99,2,[\"@title\"]],[30,0,[\"permissions\"]],[30,0,[\"routeActions\",\"goBack\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,2,[\"secondaryControls\"]],null,null,[[\"default\"],[[[[1,\"\\n            \"],[8,[30,1,[\"button\"]],null,[[\"@clicked\",\"@icon\"],[[30,0,[\"downloadFASTA\"]],[28,[37,1],[\"download\"],null]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[28,[35,3],[\"UniteSH.downloadFASTA\"],null]],[1,\"\\n            \"]],[]]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[1,2]]]]],[1,\"\\n\\n    \"],[1,[28,[35,4],null,[[\"totalCount\",\"clusterSequences\",\"clusterID\",\"thresholdOrdering\"],[[33,5,[\"totalCount\"]],[33,5,[\"clusterSequences\"]],[33,5,[\"cluster\",\"id\"]],[30,0,[\"model\",\"thresholdOrdering\"]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"navbar\",\"slots\"],false,[\"navbar/view\",\"icon\",\"title\",\"i18n-t\",\"compound-table\",\"model\"]]",
    "moduleName": "plutof/templates/compound/view.hbs",
    "isStrictMode": false
  });
});