define("plutof/controllers/dmp/edit", ["exports", "@ember/controller", "@ember/object", "plutof/mixins/edit-controller", "plutof/utils/notifications"], function (_exports, _controller, _object, _editController, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function trimValue(key, value) {
    if (typeof value === 'string') {
      const trimmed = value.trim();
      return trimmed === '' ? undefined : trimmed;
    }
    return value;
  }

  // XXX Hack for controlling fadeId property in data.
  // TODO: Refactor fade elements.
  function purgeFadeId(object) {
    const keys = Object.keys(object);
    keys.forEach(key => {
      const value = object[key];
      if (value && typeof value === 'object') {
        purgeFadeId(value);
      } else if (value instanceof Array) {
        value.forEach(purgeFadeId);
      } else if (key === 'fadeId') {
        delete object[key];
      }
    });
  }
  let DMPEditController = (_class = class DMPEditController extends _controller.default.extend(_editController.default) {
    async save() {
      await this.model.dmp.save();
      await this.model.accessRights.save();
    }
    saveDMP() {
      (0, _notifications.clearNotifications)();
      (0, _notifications.displayNotification)('status', this.i18n.t('General.saving'));

      // XXX XXX XXX
      purgeFadeId(this.model.dmp.dmp);

      // Trim strings and remove empty string values from the DMP object.
      const trimmedDmp = JSON.parse(JSON.stringify(this.model.dmp.dmp, trimValue));
      this.set('model.dmp.dmp', trimmedDmp);
      return this.save().then(() => {
        (0, _notifications.displayNotification)('success', this.i18n.t('General.done'));
        this.set('routeHasBeenLoaded', false);
        this.routeActions.goBack();
      }).catch(_notifications.reportError);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "saveDMP", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveDMP"), _class.prototype), _class);
  var _default = _exports.default = DMPEditController;
});