define("plutof/templates/datacite/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "uxf5qHi6",
    "block": "[[[10,0],[14,0,\"route-marker-datacite-index\"],[14,\"data-test\",\"route-datacite.index\"],[12],[13],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@icon\",\"@title\",\"@addRoute\",\"@pagination\",\"@infoTitle\",\"@infoContent\",\"@goBack\"],[[28,[37,1],[\"datacite\"],null],[28,[37,2],[\"Publishing.dois\"],null],[52,[30,0,[\"publishingAllowed\"]],\"datacite.add\"],[30,0,[\"model\",\"pagination\"]],[28,[37,2],[\"datacite.intro.title\"],null],\"information/content/datacite/general\",[30,0,[\"routeActions\",\"goBack\"]]]],null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@records\"],[[30,0,[\"model\",\"pagination\",\"objects\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,1],null,[[\"@label\"],[[28,[37,2],[\"PlutofListViewColumn.citation\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,5],null,[[\"@route\",\"@model\"],[\"datacite.view-private\",[30,2,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,6],[[30,2,[\"citation\"]],80],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,3,[\"modified\"]],null,null,null],[1,\"\\n\\n    \"],[8,[30,1],null,[[\"@label\"],[[28,[37,2],[\"PlutofListViewColumn.isRegistered\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,7],[[30,2,[\"registered\"]]],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[1,2,3]]]]],[1,\"\\n\"]],[\"Field\",\"doi\",\"common\"],false,[\"navbar/list\",\"icon\",\"i18n-t\",\"if\",\"tables/list-route\",\"link-to\",\"short-string\",\"read-only-boolean\"]]",
    "moduleName": "plutof/templates/datacite/index.hbs",
    "isStrictMode": false
  });
});