define("plutof/components/common/save-panel", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <StickyBottomWrapper>
      <InvalidPanels @validationContext={{@validationContext}} />
  
      <div class="action-buttons">
          <Common::SaveButton
              @save={{@save}}
              @disabled={{@validationContext.isInvalid}} />
  
          {{! TODO: Rename @goBack => @cancel }}
          <Common::CancelButton @cancel={{@goBack}} />
      </div>
  </StickyBottomWrapper>
  
  */
  {
    "id": "YCI1F1x7",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@validationContext\"],[[30,1]]],null],[1,\"\\n\\n    \"],[10,0],[14,0,\"action-buttons\"],[12],[1,\"\\n        \"],[8,[39,2],null,[[\"@save\",\"@disabled\"],[[30,2],[30,1,[\"isInvalid\"]]]],null],[1,\"\\n\\n\"],[1,\"        \"],[8,[39,3],null,[[\"@cancel\"],[[30,3]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@validationContext\",\"@save\",\"@goBack\"],false,[\"sticky-bottom-wrapper\",\"invalid-panels\",\"common/save-button\",\"common/cancel-button\"]]",
    "moduleName": "plutof/components/common/save-panel.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});