define("plutof/templates/taxon-description/add", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "odApCJqA",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"taxon-description.add\"]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@title\",\"@icon\",\"@infoContent\",\"@reset\",\"@goBack\"],[[28,[37,2],[\"TaxonDescription.newTaxonDesc\"],null],[28,[37,3],[\"taxon-description\"],null],\"information/content/general/info-empty\",[30,0,[\"routeActions\",\"triggerReset\"]],[30,0,[\"routeActions\",\"goBack\"]]]],null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@model\",\"@validationContext\"],[[30,0,[\"model\",\"taxonDescription\"]],[30,0,[\"validationContext\"]]]],null],[1,\"\\n\\n\"],[8,[39,5],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,6],null,[[\"@validationContext\"],[[30,0,[\"validationContext\"]]]],null],[1,\"\\n\\n    \"],[10,0],[14,0,\"action-buttons\"],[12],[1,\"\\n        \"],[8,[39,7],null,[[\"@save\",\"@disabled\"],[[30,0,[\"save\"]],[30,0,[\"validationContext\",\"isInvalid\"]]]],null],[1,\"\\n\\n        \"],[8,[39,8],null,[[\"@cancel\"],[[28,[37,9],[[30,0],\"cancel\"],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"test/route-marker\",\"navbar/edit\",\"i18n-t\",\"icon\",\"taxonomy-lab/taxon-description/edit\",\"sticky-bottom-wrapper\",\"invalid-panels\",\"common/save-button\",\"common/cancel-button\",\"action\"]]",
    "moduleName": "plutof/templates/taxon-description/add.hbs",
    "isStrictMode": false
  });
});