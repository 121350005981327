define("plutof/templates/components/navbar/edit", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "xHgWutAD",
    "block": "[[[8,[39,0],null,[[\"@kind\"],[[28,[37,1],[[30,1],\"edit\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,3,[\"header\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[30,2,[\"header\"]],null,[[\"@icon\",\"@title\"],[[30,4],[30,5]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,3,[\"primaryControls\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[30,2,[\"info\"]],null,[[\"@title\",\"@content\"],[[28,[37,1],[[30,6],[28,[37,1],[[30,5],[28,[37,2],[\"General.Info\"],null]],null]],null],[30,7]]],null],[1,\"\\n\\n        \"],[18,10,[[30,2],[28,[37,4],null,[[\"primaryControls\"],[[50,\"named-slot\",0,null,[[\"tagName\"],[\"\"]]]]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,3,[\"secondaryControls\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[18,10,[[30,2],[28,[37,4],null,[[\"secondaryControls\"],[[50,\"named-slot\",0,null,[[\"tagName\"],[\"\"]]]]]]]],[1,\"\\n\\n        \"],[8,[30,2,[\"bookmark\"]],null,null,null],[1,\"\\n\\n\"],[41,[30,8],[[[1,\"            \"],[8,[30,2,[\"button\"]],null,[[\"@clicked\"],[[28,[37,7],[[30,0],[30,8]],null]]],[[\"default\"],[[[[1,\"\\n                \"],[10,1],[14,0,\"fas fa-sync-alt\"],[12],[13],[1,\"\\n                \"],[1,[28,[35,2],[\"General.Reset\"],null]],[1,\"\\n            \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n        \"],[8,[30,2,[\"goBack\"]],null,[[\"@goBack\"],[[30,9]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[2,3]]]]],[1,\"\\n\"]],[\"@kind\",\"navbar\",\"slots\",\"@icon\",\"@title\",\"@infoTitle\",\"@infoContent\",\"@reset\",\"@goBack\",\"&default\"],false,[\"navbar/navbar-base\",\"or\",\"i18n-t\",\"yield\",\"hash\",\"component\",\"if\",\"action\"]]",
    "moduleName": "plutof/templates/components/navbar/edit.hbs",
    "isStrictMode": false
  });
});