define("plutof/components/validation/group", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @context}}
      {{yield (hash
          part=(component "validation/-group/part" parent=@context)
          split=(component "validation/split-context" context=@context)
      )}}
  {{else}}
      {{yield}}
  {{/if}}
  
  */
  {
    "id": "5NgxPGxG",
    "block": "[[[41,[30,1],[[[1,\"    \"],[18,2,[[28,[37,2],null,[[\"part\",\"split\"],[[50,\"validation/-group/part\",0,null,[[\"parent\"],[[30,1]]]],[50,\"validation/split-context\",0,null,[[\"context\"],[[30,1]]]]]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[18,2,null],[1,\"\\n\"]],[]]]],[\"@context\",\"&default\"],false,[\"if\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "plutof/components/validation/group.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});