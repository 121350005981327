define("plutof/utils/modules", ["exports", "@ember/utils", "plutof/config/environment", "plutof/helpers/icon", "plutof/utils/i18n"], function (_exports, _utils, _environment, _icon, _i18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.getContentTypeModule = getContentTypeModule;
  _exports.getModelContentType = getModelContentType;
  _exports.getRecordContentType = getRecordContentType;
  _exports.getRecordModule = getRecordModule;
  _exports.get_model_icon = get_model_icon;
  _exports.get_view_route = get_view_route;
  const i18n = (0, _i18n.getI18n)();
  function standardRoutes(root) {
    return {
      list: `${root}.index`,
      add: `${root}.add`,
      edit: `${root}.edit`,
      view: `${root}.view`
    };
  }
  function annotationRoutes(root) {
    return {
      annotations: {
        add: `${root}.annotation.add`,
        edit: `${root}.annotation.edit`,
        view: `${root}.annotation.view`
      }
    };
  }
  const MODULES = {
    'study/study': {
      name: i18n.t('General.Project'),
      icon: (0, _icon.icon)('project'),
      routes: standardRoutes('study')
    },
    'study/dmp': {
      name: i18n.t('dmp.general.dmp'),
      icon: (0, _icon.icon)('dmp'),
      routes: standardRoutes('dmp')
    },
    'sample/samplingevent': {
      name: i18n.t('General.samplingEvent'),
      icon: (0, _icon.icon)('event'),
      routes: {
        add: 'event.add',
        edit: 'event.edit',
        view: 'event.view'
      }
    },
    'sample/gatheringagent': {
      icon: (0, _icon.icon)('person')
    },
    'sample/samplingarea': {
      name: i18n.t('General.samplingArea'),
      icon: (0, _icon.icon)('area'),
      routes: standardRoutes('area')
    },
    'filerepository/file': {
      name: i18n.t('FileRepository.file'),
      icon: (0, _icon.icon)('FileRepository.file'),
      routes: {
        list: 'filerepository.index',
        add: 'filerepository.upload',
        edit: 'filerepository.edit',
        view: 'filerepository.view'
      }
    },
    'filerepository/item': {
      icon: (0, _icon.icon)('file')
    },
    'specimen/specimen': {
      name: i18n.t('Specimen.specimen'),
      icon: (0, _icon.icon)('specimen'),
      model: 'taxonoccurrence/specimen/specimen',
      routes: Object.assign(standardRoutes('specimen'), annotationRoutes('specimen')),
      clipboard: {
        url: _environment.default.API_HOST + '/clipboard/clipboards/specimens/',
        id: 'specimens'
      }
    },
    'specimen/additionalidentifier': {
      icon: (0, _icon.icon)('specimen')
    },
    'specimen/preparing_agent': {
      icon: (0, _icon.icon)('person')
    },
    'sequence/sequence': {
      name: i18n.t('Sequences.sequence'),
      icon: (0, _icon.icon)('sequence'),
      model: 'taxonoccurrence/sequence/sequence',
      routes: Object.assign(standardRoutes('sequence'), annotationRoutes('sequence')),
      clipboard: {
        url: _environment.default.API_HOST + '/clipboard/clipboards/sequences/',
        id: 'sequences'
      }
    },
    'observation/observation': {
      name: i18n.t('Observations.observation'),
      icon: (0, _icon.icon)('observation'),
      model: 'taxonoccurrence/observation/observation',
      routes: standardRoutes('observation'),
      clipboard: {
        url: _environment.default.API_HOST + '/clipboard/clipboards/observations/',
        id: 'observations'
      }
    },
    'livingculture/strain': {
      name: i18n.t('LivingCultures.livingCulture'),
      icon: (0, _icon.icon)('livingspecimen'),
      model: 'taxonoccurrence/livingculture/strain',
      routes: Object.assign(standardRoutes('livingspecimen'), annotationRoutes('livingspecimen')),
      clipboard: {
        url: _environment.default.API_HOST + '/clipboard/clipboards/livingcultures/',
        id: 'livingcultures'
      }
    },
    'referencebased/occurrence': {
      name: i18n.t('General.RefBased'),
      icon: (0, _icon.icon)('refbased'),
      model: 'taxonoccurrence/referencebased/occurrence',
      routes: standardRoutes('referencebased'),
      clipboard: {
        url: _environment.default.API_HOST + '/clipboard/clipboards/occurrences/',
        id: 'occurrences'
      }
    },
    'materialsample/materialsample': {
      name: i18n.t('MaterialSamples.viewSample'),
      icon: (0, _icon.icon)('materialsample'),
      model: 'taxonoccurrence/materialsample/materialsample',
      routes: standardRoutes('materialsample'),
      clipboard: {
        url: _environment.default.API_HOST + '/clipboard/clipboards/materialsamples/',
        id: 'materialsamples'
      }
    },
    'reference/reference': {
      icon: (0, _icon.icon)('reference'),
      name: i18n.t('References.reference'),
      routes: standardRoutes('reference')
    },
    'reference/series': {
      icon: (0, _icon.icon)('reference'),
      routes: standardRoutes('series')
    },
    'reference/publisher': {
      icon: (0, _icon.icon)('reference'),
      routes: standardRoutes('publisher')
    },
    'reference/item': {
      icon: (0, _icon.icon)('reference')
    },
    'reference/externallink': {
      icon: (0, _icon.icon)('reference')
    },
    'glossary/glossary': {
      icon: (0, _icon.icon)('glossary'),
      routes: standardRoutes('glossary')
    },
    'glossary/collection': {
      icon: (0, _icon.icon)('glossary-collection'),
      routes: standardRoutes('glossary.collection')
    },
    'glossary/item': {
      icon: (0, _icon.icon)('glossary')
    },
    'agent/person': {
      name: i18n.t('Agents.person.person'),
      icon: (0, _icon.icon)('person'),
      routes: standardRoutes('person')
    },
    'agent/organization': {
      name: i18n.t('Agents.organization.organization'),
      icon: (0, _icon.icon)('organization'),
      routes: standardRoutes('organization')
    },
    'agent/collection': {
      name: i18n.t('collection.collection'),
      icon: (0, _icon.icon)('collection'),
      routes: standardRoutes('collection')
    },
    'taxonomylab/taxondescription': {
      icon: (0, _icon.icon)('taxon-description'),
      name: i18n.t('TaxonDescription.taxonDesc'),
      routes: standardRoutes('taxon-description')
    },
    'taxonomy/taxonnode': {
      icon: (0, _icon.icon)('taxon-node'),
      routes: {
        list: 'taxonomy.trees',
        add: 'taxonomy.add',
        edit: 'taxonomy.edit',
        view: 'taxonomy.view'
      }
    },
    'taxonomy/tree': {
      icon: (0, _icon.icon)('taxonomy'),
      routes: {
        list: 'taxonomy.trees',
        add: 'taxonomy.addtree',
        edit: 'taxonomy.edittree'
      }
    },
    'taxonomy/commonname': {
      icon: (0, _icon.icon)('taxonomy'),
      routes: {
        list: 'taxonomy.trees',
        add: 'taxonomy.add',
        edit: 'taxonomy.edit',
        view: 'taxonomy.view'
      }
    },
    'globalkey/referencesequence': {
      icon: (0, _icon.icon)('unite-hypothesis'),
      routes: {
        list: 'unite.referencesequences',
        view: 'unite.view'
      }
    },
    'globalkey/dshcluster': {
      icon: (0, _icon.icon)('unite-sh-cluster'),
      routes: {
        list: 'unite.referencesequences',
        view: 'unite.view'
      }
    },
    'globalkey/determination': {
      icon: (0, _icon.icon)('unite-sh-cluster'),
      name: i18n.t('UniteSH.determinations')
    },
    'globalkey/shdetermination': {
      icon: (0, _icon.icon)('unite-sh-cluster')
    },
    'photobank/collectionobject': {
      icon: (0, _icon.icon)('photobank'),
      name: i18n.t('Photobank.viewObject'),
      routes: standardRoutes('photobank'),
      clipboard: {
        url: _environment.default.API_HOST + '/clipboard/clipboards/photobanks/',
        id: 'collectionobjects'
      }
    },
    'datacite/metadata': {
      icon: (0, _icon.icon)('datacite'),
      routes: {
        list: 'datacite.index',
        add: 'datacite.add',
        edit: 'datacite.edit',
        view: 'datacite.view-private'
      }
    },
    'forms': {
      icon: (0, _icon.icon)('form'),
      routes: standardRoutes('forms')
    },
    'collection_lab/conditionassessment': {
      icon: (0, _icon.icon)('condition-assessment'),
      name: i18n.t('CollectionManagement.CA'),
      routes: standardRoutes('conditionassessment')
    },
    'collection_lab/treatment': {
      icon: (0, _icon.icon)('treatment'),
      name: i18n.t('CollectionManagement.treatment'),
      routes: standardRoutes('treatment')
    },
    'determination/determination': {
      icon: (0, _icon.icon)('taxonomy')
    },
    'determination/determiner': {
      icon: (0, _icon.icon)('person')
    },
    'measurement/measurement': {
      icon: (0, _icon.icon)('trait'),
      name: i18n.t('traits.traits'),
      routes: standardRoutes('trait')
    },
    'measurement/objectmeasurement': {
      icon: (0, _icon.icon)('trait'),
      name: i18n.t('traits.measurements')
    },
    'measurement/mainform': {
      icon: (0, _icon.icon)('form'),
      name: i18n.t('General.mainformName'),
      routes: standardRoutes('forms')
    },
    'transaction/transaction': {
      icon: (0, _icon.icon)('transcation'),
      name: i18n.t('transactions.transaction'),
      routes: standardRoutes('transaction')
    },
    'dnalab/dnaextraction': {
      icon: (0, _icon.icon)('experiment'),
      name: i18n.t('experiment.extraction.routeTitles.view'),
      routes: standardRoutes('experiment.dna-extraction')
    },
    'dnalab/pcr': {
      icon: (0, _icon.icon)('experiment'),
      name: i18n.t('experiment.pcr.routeTitles.view'),
      routes: standardRoutes('experiment.pcr')
    },
    'dnalab/sequencing': {
      icon: (0, _icon.icon)('experiment'),
      name: i18n.t('experiment.sequencing.routeTitles.view'),
      routes: standardRoutes('experiment.sequencing')
    },
    'redbook/redlist': {
      icon: (0, _icon.icon)('conservation'),
      name: i18n.t('conservation.redList.list'),
      routes: standardRoutes('conservation-lab.red-list')
    },
    'redbook/redlistassessment': {
      icon: (0, _icon.icon)('conservation'),
      name: i18n.t('conservation.redListAssessment.assessment'),
      routes: standardRoutes('conservation-lab.red-list-assessment')
    },
    'permit/permit': {
      icon: (0, _icon.icon)('permit'),
      name: i18n.t('permit.permit'),
      routes: standardRoutes('permit')
    },
    'permit/item': {
      icon: (0, _icon.icon)('permit')
    },
    'analysis/run': {
      icon: (0, _icon.icon)('analysis'),
      name: i18n.t('Analysis.analysis'),
      routes: standardRoutes('analysis')
    },
    'determination/cultivargroup': {
      model: 'determination/cultivar-group'
    }
  };
  function get_view_route(content_type) {
    const moduleDesc = getContentTypeModule(content_type);
    if ((0, _utils.isNone)(moduleDesc)) {
      return null;
    }
    return moduleDesc.routes.view;
  }
  function get_model_icon(content_type) {
    const moduleDesc = getContentTypeModule(content_type);
    if ((0, _utils.isNone)(moduleDesc)) {
      return null;
    }
    return moduleDesc.icon;
  }
  const MODEL_CTYPE_EXCEPTIONS = {
    'determination/cultivar-group': 'determination/cultivargroup',
    'dna-lab/dna-extraction': 'dnalab/dnaextraction',
    'dna-lab/dna': 'dnalab/dna',
    'dna-lab/pcr-product': 'dnalab/pcrproduct',
    'dna-lab/pcr': 'dnalab/pcr',
    'dna-lab/polymerase-mix': 'dnalab/polymerasemix',
    'dna-lab/primer': 'dnalab/primer',
    'dna-lab/sequencing-result': 'dnalab/sequencingresult',
    'dna-lab/sequencing': 'dnalab/sequencing',
    'collection-lab/condition-assessment': 'collection_lab/conditionassessment',
    'collection-lab/treament-item': 'collection_lab/treatmentitem',
    'collection-lab/treatment': 'collection_lab/treatment',
    'redbook/red-list': 'redbook/redlist',
    'redbook/red-list-assessment': 'redbook/redlistassessment',
    'users/user': 'auth/user'
  };
  function getModelContentType(modelName) {
    if (MODEL_CTYPE_EXCEPTIONS[modelName]) {
      return MODEL_CTYPE_EXCEPTIONS[modelName];
    }
    const parts = modelName.split('/');
    return `${parts[parts.length - 2]}/${parts[parts.length - 1]}`;
  }
  function getRecordContentType(record) {
    return getModelContentType(record.constructor.modelName);
  }

  // XXX TODO: Need a central way to hold model -> ctype-string mapping. Parts of it are everywhere,
  // duplicated and incomplete and I'm so lost. Is model name the primary type identity? Is content_type?
  // What a mess
  function getRecordModule(record) {
    return MODULES[getRecordContentType(record)];
  }
  function getContentTypeModule(content_type) {
    return MODULES[content_type.get('full_name')];
  }
  var _default = _exports.default = MODULES;
});