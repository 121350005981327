define("plutof/templates/experiment/sequencing/edit", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "jjPXSZ62",
    "block": "[[[10,0],[14,0,\"route-marker-sequencing-edit\"],[12],[13],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@icon\",\"@title\",\"@record\",\"@goBack\",\"@infoTitle\",\"@infoContent\"],[[28,[37,1],[\"sequencing\"],null],[28,[37,2],[\"experiment.sequencing.routeTitles.edit\"],null],[30,0,[\"model\",\"experiment\"]],[30,0,[\"routeActions\",\"goBack\"]],[28,[37,2],[\"experiment.general.experiments\"],null],\"information/content/dna-lab/general\"]],null],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@experiment\",\"@materials\",\"@linkedItemsData\",\"@validationContext\",\"@cancel\",\"@save\"],[[30,0,[\"model\",\"experiment\"]],[30,0,[\"model\",\"materials\"]],[30,0,[\"model\",\"linkedItemsData\"]],[30,0,[\"validationContext\"]],[28,[37,4],[[30,0],\"cancel\"],null],[28,[37,4],[[30,0],\"saveExperiment\"],null]]],null],[1,\"\\n\"]],[],false,[\"navbar/edit\",\"icon\",\"i18n-t\",\"experiment/sequencing/edit-form\",\"action\"]]",
    "moduleName": "plutof/templates/experiment/sequencing/edit.hbs",
    "isStrictMode": false
  });
});