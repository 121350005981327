define("plutof/controllers/publishing/gbif/view", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service", "@ember/utils", "plutof/config/environment", "plutof/misc/abstract", "plutof/utils/i18n", "plutof/utils/notifications"], function (_exports, _controller, _object, _computed, _service, _utils, _environment, _abstract, _i18n, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const i18n = (0, _i18n.getI18n)();
  let PublishingGbifViewController = (_dec = (0, _object.computed)('model.dataset.{gbif_uuid,publishing_in_progress,gbif_upload_in_progress,records_pending}'), _dec2 = (0, _object.computed)('model.dataset.can_edit', 'haveFullAccess'), _dec3 = (0, _object.computed)('model.dataset.gbif_uuid'), _dec4 = (0, _object.computed)('model.{dataset.people,model.agentTypes}'), _dec5 = (0, _object.computed)('user.username', 'model.dataset.owner'), _dec6 = (0, _computed.or)('isOwned', 'isModerator'), _dec7 = (0, _computed.or)('model.dataset.{publishing_in_progress,gbif_upload_in_progress}'), _dec8 = (0, _object.computed)('cannotPublish', 'haveFullAccess'), _class = class PublishingGbifViewController extends _controller.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "confirmationDialog", _descriptor2, this);
      _initializerDefineProperty(this, "settings", _descriptor3, this);
      _initializerDefineProperty(this, "haveFullAccess", _descriptor4, this);
      _initializerDefineProperty(this, "cannotPublish", _descriptor5, this);
    }
    init() {
      super.init(...arguments);
      this.set('isModerator', false);
      this.settings.wait().then(profile => profile.user).then(user => {
        this.set('user', user);
      });
    }
    get isDeletable() {
      const {
        gbif_uuid,
        publishing_in_progress,
        gbif_upload_in_progress,
        records_pending
      } = this.model.dataset;
      return ![!(0, _utils.isEmpty)(gbif_uuid), publishing_in_progress, gbif_upload_in_progress, records_pending].some(flag => flag);
    }
    get navbarPermissions() {
      return {
        canDelete: this.haveFullAccess,
        canModify: this.model.dataset.can_edit
      };
    }
    get GbifUrl() {
      const uuid = this.get('model.dataset.gbif_uuid');
      if ((0, _utils.isEmpty)(uuid)) {
        return null;
      } else {
        return `${_environment.default.GBIF_HOST}dataset/${uuid}`;
      }
    }
    get agents() {
      const people = this.get('model.dataset.people');
      const types = this.get('model.agentTypes');
      return people.map(agent => {
        const type = types[agent.agent_type - 1];

        // TODO: Think about merging first and last names.
        return {
          email: agent.email,
          first_name: agent.first_name,
          last_name: agent.last_name,
          position: agent.position,
          orcid_identifier: agent.orcid_identifier,
          type: (0, _utils.isPresent)(type) ? type.display_name : null
        };
      });
    }
    get isOwned() {
      return this.get('user.username') === this.model.dataset.owner;
    }
    get publishingDisabled() {
      return !this.model.dataset.is_verified && (this.cannotPublish || !this.haveFullAccess);
    }
    generateDwCA() {
      const endpoint = `${_environment.default.PUBLISHING_API_HOST}/datasets/${this.model.dataset.id}/generate-dwca/`;
      (0, _notifications.clearNotifications)();
      (0, _notifications.displayNotification)('status', this.i18n.t('dataResource.generateDwCA.process'));
      return this.ajax.post(endpoint).then(({
        async
      }) => {
        const message = async ? 'dataResource.generateDwCA.success' : 'General.done';
        (0, _notifications.displayNotification)('success', this.i18n.t(message));
        if (!async) {
          this.model.history.reload();
        }
      }).catch(_notifications.reportError);
    }
    confirmPublication() {
      return this.confirmationDialog.confirm({
        title: this.i18n.t('dataResource.dialogs.publish.title'),
        content: this.i18n.t('dataResource.dialogs.publish.content'),
        labels: {
          yes: this.i18n.t('dataResource.dialogs.publish.confirm'),
          no: this.i18n.t('dataResource.dialogs.publish.cancel')
        }
      });
    }
    publish() {
      return this.confirmPublication().then(confirmed => {
        if (!confirmed) {
          return _abstract.EMPTY_PROMISE;
        }
        (0, _notifications.clearNotifications)();
        (0, _notifications.displayNotification)('status', i18n.t('General.saving'));
        const dataset = this.get('model.dataset');
        const url = `${_environment.default.PUBLISHING_API_HOST}/datasets/${dataset.get('id')}/publish/`;
        return this.ajax.post(url).then(response => {
          (0, _notifications.displayNotification)('success', i18n.t('General.done'));
          this.set('routeHasBeenLoaded', false);
          this.routeActions.goBack();
          return response;
        }).catch(_notifications.reportError);
      });
    }
    publishHistoryRecord(record) {
      return this.confirmPublication().then(confirmed => {
        if (!confirmed) {
          return _abstract.EMPTY_PROMISE;
        }
        const endpoint = `${_environment.default.PUBLISHING_API_HOST}/history-records/${record.id}/publish/`;
        const published = this.ajax.post(endpoint).then(() => {
          record.set('published', true);
        });
        return (0, _notifications.notifyProcess)(published, this.i18n);
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "confirmationDialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "settings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isDeletable", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "isDeletable"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "navbarPermissions", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "navbarPermissions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "GbifUrl", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "GbifUrl"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "agents", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "agents"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isOwned", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "isOwned"), _class.prototype), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "haveFullAccess", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "cannotPublish", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "publishingDisabled", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "publishingDisabled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "generateDwCA", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "generateDwCA"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "publish", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "publish"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "publishHistoryRecord", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "publishHistoryRecord"), _class.prototype), _class);
  var _default = _exports.default = PublishingGbifViewController;
});