define("plutof/templates/analysis/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "ZOZfkSA/",
    "block": "[[[10,0],[14,0,\"route-marker-analysis-index\"],[14,\"data-test\",\"route-analysis.index\"],[12],[13],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@icon\",\"@title\",\"@addRoute\",\"@pagination\",\"@infoTitle\",\"@infoContent\",\"@goBack\"],[[28,[37,1],[\"analysis\"],null],[28,[37,2],[\"General.SequenceAnalyze\"],null],\"analysis.add\",[30,0,[\"model\",\"pagination\"]],[28,[37,2],[\"General.Info\"],null],\"information/content/analysis/index\",[30,0,[\"routeActions\",\"goBack\"]]]],null],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@model\"],[\"analysis.run\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,4],null,[[\"@records\"],[[30,0,[\"model\",\"pagination\",\"objects\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,2],null,[[\"@label\"],[[28,[37,2],[\"PlutofListViewColumn.name\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,5],null,[[\"@run\"],[[30,3]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,2],null,[[\"@label\"],[[28,[37,2],[\"PlutofListViewColumn.type\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,6],[[30,1,[\"analysis_type\",\"choices\"]],[30,3,[\"analysis_type\"]]],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,2],null,[[\"@label\"],[[28,[37,2],[\"PlutofListViewColumn.status\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,2],[[28,[37,7],[\"Analysis.status.\",[30,3,[\"status\"]]],null]],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,2],null,[[\"@label\"],[[28,[37,2],[\"PlutofListViewColumn.estimated\"],null]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,8],[[30,3,[\"estimated_finish_time\"]]],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[2,3]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"translations\",\"Field\",\"run\"],false,[\"navbar/list\",\"icon\",\"i18n-t\",\"i18n/model-strings\",\"tables/list-route\",\"analysis/-list-route-link\",\"get\",\"concat\",\"formatted-date\"]]",
    "moduleName": "plutof/templates/analysis/index.hbs",
    "isStrictMode": false
  });
});