define("plutof/routes/referencebased/add", ["exports", "@ember/service", "@ember/utils", "rsvp", "plutof/components/linked-items/edit", "plutof/components/layer/occurrence-areas", "plutof/components/referencebased/refbased-table", "plutof/utils/access", "plutof/utils/cloning", "plutof/utils/model", "plutof/utils/routes"], function (_exports, _service, _utils, _rsvp, _edit, _occurrenceAreas, _refbasedTable, _access, _cloning, _model, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ReferencebasedAddRoute = (_class = class ReferencebasedAddRoute extends _routes.AddRoute {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "fileUpload", _descriptor2, this);
      _defineProperty(this, "defaultReturnRoute", 'referencebased.index');
      _defineProperty(this, "title", 'ReferenceBased.newRefBased');
    }
    cloneReferenceBased(template, cloneArea, cloneEvent) {
      const store = this.store;
      return _rsvp.default.hash({
        samplingData: (0, _model.getOccurrenceSamplingData)(store, template).then(samplingData => {
          return (0, _cloning.clone_sampling_data)(store, samplingData.formData, cloneArea, cloneEvent);
        }),
        layers: _occurrenceAreas.LinkedLayersModel.clone({
          ajax: this.ajax,
          templateOccurrence: template
        }),
        reference: template.get('reference')
      });
    }
    async createReferenceBasedData(params) {
      if (!(0, _utils.isNone)(params) && !(0, _utils.isNone)(params.template_id)) {
        const template = await this.store.findRecord('taxonoccurrence/referencebased/occurrence', params.template_id);
        return this.cloneReferenceBased(template, params.clone_area === 'true', params.clone_event === 'true');
      }
      let reference = null;
      if (!(0, _utils.isNone)(params.reference_id)) {
        reference = await this.store.findRecord('reference/reference', params.reference_id);
      }
      const {
        formData
      } = await (0, _model.getSamplingDataForParams)(this.store, params);
      return {
        samplingData: formData,
        layers: _occurrenceAreas.LinkedLayersModel.create(this.ajax),
        reference
      };
    }
    model(params) {
      if (this.get('controller.routeHasBeenLoaded')) {
        return undefined;
      }

      // Sampling is presented within event, area or is new(empty)
      return this.createReferenceBasedData(params).then(data => {
        return _rsvp.default.hash({
          reference: data.reference,
          tableData: (0, _refbasedTable.createRefbasedTableData)({
            store: this.store,
            fileUpload: this.fileUpload
          }),
          areaEventData: data.samplingData,
          layers: data.layers,
          accessRights: (0, _access.create_access_rights)(this.store),
          itemData: (0, _edit.createMultipleLinkedItemsData)(this.store, {
            useEventFiles: true,
            disabledTabs: ['references', 'permits']
          })
        });
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fileUpload", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  var _default = _exports.default = ReferencebasedAddRoute;
});