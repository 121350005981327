define("plutof/templates/specimen/annotation/add", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "UI02lgrz",
    "block": "[[[8,[39,0],null,[[\"@kind\",\"@title\",\"@icon\",\"@infoContent\",\"@goBack\"],[\"annotation\",[28,[37,1],[\"Specimen.annotation.add\"],null],[28,[37,2],[\"specimen\"],null],\"information/specimen/edit\",[30,0,[\"routeActions\",\"goBack\"]]]],null],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@data\",\"@canModifyDeterminations\",\"@showAreaEditButton\",\"@canResetArea\",\"@preserveZoom\",\"@canModifyOwner\",\"@validationContext\",\"@disableDeterminationsEditButton\"],[[30,0,[\"model\",\"specimenData\"]],false,false,false,false,false,[30,0,[\"validationContext\"]],true]],null],[1,\"\\n\\n\"],[8,[39,4],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,5],null,[[\"@validationContext\"],[[30,0,[\"validationContext\"]]]],null],[1,\"\\n\\n    \"],[10,0],[14,0,\"action-buttons\"],[12],[1,\"\\n        \"],[8,[39,6],null,[[\"@annotate\",\"@disabled\"],[[30,0,[\"annotate\"]],[30,0,[\"validationContext\",\"isInvalid\"]]]],null],[1,\"\\n\\n        \"],[8,[39,7],null,[[\"@cancel\"],[[30,0,[\"routeActions\",\"goBack\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"navbar/edit\",\"i18n-t\",\"icon\",\"specimen/edit\",\"sticky-bottom-wrapper\",\"invalid-panels\",\"annotation/annotate-button\",\"common/cancel-button\"]]",
    "moduleName": "plutof/templates/specimen/annotation/add.hbs",
    "isStrictMode": false
  });
});