define("plutof/templates/conservation-lab/red-list/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "rcBBC3KT",
    "block": "[[[8,[39,0],null,[[\"@icon\",\"@title\",\"@addRoute\",\"@goBack\",\"@pagination\"],[[28,[37,1],[\"conservation\"],null],[28,[37,2],[\"conservation.redList.index.title\"],null],\"conservation-lab.red-list.add\",[30,0,[\"routeActions\",\"goBack\"]],[30,0,[\"model\",\"pagination\"]]]],null],[1,\"\\n\\n\"],[8,[39,3],null,[[\"@model\"],[\"redbook.red-list\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,4],null,[[\"@records\"],[[30,0,[\"model\",\"pagination\",\"objects\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,2],null,[[\"@label\"],[[30,1,[\"name\",\"label\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,5],null,[[\"@route\",\"@model\"],[\"conservation-lab.red-list.view\",[30,3,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[30,3,[\"representation\"]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[8,[30,2],null,[[\"@label\"],[[30,1,[\"created_by\",\"label\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,3,[\"created_by\",\"representation\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[2,3]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"translations\",\"Field\",\"redlist\"],false,[\"navbar/list\",\"icon\",\"i18n-t\",\"i18n/model-strings\",\"tables/list-route\",\"link-to\"]]",
    "moduleName": "plutof/templates/conservation-lab/red-list/index.hbs",
    "isStrictMode": false
  });
});