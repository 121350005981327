define("plutof/templates/event/edit", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "FA/lpH7T",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"event.edit\"]],null],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@icon\",\"@title\",\"@record\",\"@goBack\"],[[28,[37,2],[\"event\"],null],[28,[37,3],[\"SamplingEvent.edit\"],null],[30,0,[\"model\",\"event\"]],[30,0,[\"routeActions\",\"goBack\"]]]],null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@event\",\"@habitat\",\"@measurementsData\",\"@gatheringAgents\",\"@habitatMeasurementsData\",\"@associatedData\",\"@validationContext\"],[[30,0,[\"model\",\"event\"]],[30,0,[\"model\",\"habitat\"]],[30,0,[\"model\",\"measurementsData\"]],[30,0,[\"model\",\"gatheringAgents\"]],[30,0,[\"model\",\"habitatMeasurementsData\"]],[30,0,[\"model\",\"itemData\"]],[30,0,[\"validationContext\"]]]],null],[1,\"\\n\\n\"],[10,0],[14,0,\"action-buttons\"],[12],[1,\"\\n    \"],[8,[39,5],null,[[\"@save\",\"@disabled\"],[[30,0,[\"save\"]],[30,0,[\"validationContext\",\"isInvalid\"]]]],null],[1,\"\\n\\n    \"],[8,[39,6],null,[[\"@cancel\"],[[30,0,[\"routeActions\",\"goBack\"]]]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"test/route-marker\",\"navbar/edit\",\"icon\",\"i18n-t\",\"event/edit\",\"common/save-button\",\"common/cancel-button\"]]",
    "moduleName": "plutof/templates/event/edit.hbs",
    "isStrictMode": false
  });
});